/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */

/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var TRACKING_GLOBAL = 'tracking:global';

/**
 * Build the initial tealium header object
 * @param {Object} data - data object
 * @returns {Object} initial tealium header data
 */
function initHeaderObject(data) {
    return {
        event_type: 'header_nav_click',
        page_name: data ? data.page_name : '',
        ga_action: 'Header',
        site: data ? data.site : '',
        site_section: data ? data.site_section : '',
        page_group: data ? data.page_group : '',
        page_context_type: data ? data.page_context_type : '',
        page_context_title: data ? data.page_context_title : '',
        login_status: data ? data.login_status : '',
        site_type: data ? data.site_type : '',
        element_type: 'A',
        user_id: data ? data.user_id : '',
        ga4_event: 'navigation_click',
        nav_type: 'header nav',
        page_location: window.location.pathname
    };
}

var header = {
    init: function () {
        // Header navbar link click events
        $('.tl_navbar a').click(function (e) {
            var tl_sub_navbar = $(e.target).closest('.tl_sub_navbar');
            var tl_sub_sub_navbar = $(e.target).closest('.tl_sub_sub_navbar');
            var tl_sub_navbar_image = $(e.target).closest('.tl_sub_navbar_image');
            var obj = tealium.checkElement(e);
            var l1text = '';
            var l2text = '';

            if (window.utag_data) {
                var data = window.utag_data;
                var $parent = $(e.target).closest('.dropdown-menu').parent()[0];

                // Header main navbar link click events
                if (tl_sub_navbar.length === 0 && tl_sub_sub_navbar.length === 0 && tl_sub_navbar_image.length === 0) {
                    var headerObject = initHeaderObject(data);

                    headerObject.nav_click = 'Header:' + obj.label;
                    headerObject.event_message = 'Header:' + obj.label;
                    headerObject.element_text = obj.label;
                    headerObject.ga_label = 'Header:' + obj.label;
                    headerObject.nav_level1 = obj.label;
                    headerObject.nav_level2 = '';
                    headerObject.nav_level3 = '';

                    tealium.triggerEvent(headerObject);

                    // Header sub navbar link click events
                } else if (tl_sub_navbar.length !== 0 && tl_sub_sub_navbar.length === 0 && tl_sub_navbar_image.length === 0) {
                    var headerSubObject = initHeaderObject(data);
                    var labelSub = '';

                    if ($parent && $parent.children[0] && $parent.children[0].text) {
                        l1text = $parent.children[0].text.trim();
                        labelSub = l1text + ':' + obj.label;
                    }

                    headerSubObject.nav_click = 'Header:' + labelSub;
                    headerSubObject.event_message = 'Header:' + labelSub;
                    headerSubObject.element_text = obj.label;
                    headerSubObject.ga_label = labelSub;
                    headerSubObject.nav_level1 = l1text;
                    headerSubObject.nav_level2 = obj.label;
                    headerSubObject.nav_level3 = '';

                    tealium.triggerEvent(headerSubObject);

                    // Header sub sub navbar link click events
                } else if (tl_sub_sub_navbar.length !== 0 && tl_sub_navbar_image.length === 0) {
                    var labelSub_sub = '';
                    var $subNavbar = $(e.target).closest('.tl_sub_navbar')[0];
                    var headerSubSubObject = initHeaderObject(data);

                    if ($parent && $parent.children[0] && $parent.children[0].text && $subNavbar && $subNavbar.children[0] && $subNavbar.children[0].text) {
                        l1text = $parent.children[0].text.trim();
                        l2text = $subNavbar.children[0].text.trim();
                        labelSub_sub = l1text + ':' + l2text + ':' + obj.label;
                    }

                    headerSubSubObject.nav_click = 'Header:' + labelSub_sub;
                    headerSubSubObject.event_message = 'Header:' + labelSub_sub;
                    headerSubSubObject.element_text = obj.label;
                    headerSubSubObject.ga_label = labelSub_sub;
                    headerSubSubObject.nav_level1 = l1text;
                    headerSubSubObject.nav_level2 = l2text;
                    headerSubSubObject.nav_level3 = obj.label;

                    tealium.triggerEvent(headerSubSubObject);

                    // Header content image link click events
                } else if (tl_sub_navbar_image.length !== 0) {
                    var headerImgObject = initHeaderObject(data);
                    l1text = $parent && $parent.children[0] && $parent.children[0].text ? $parent.children[0].text.trim() : '';

                    if (obj.label === '') {
                        obj.label = $(e.target).parent().siblings()[0].text.trim();
                    }

                    headerImgObject.nav_click = 'Header:' + obj.label;
                    headerImgObject.event_message = 'Header:' + obj.label;
                    headerImgObject.element_text = obj.label;
                    headerImgObject.ga_label = l1text + ':' + obj.label;
                    headerImgObject.nav_level1 = l1text;
                    headerImgObject.nav_level2 = obj.label;
                    headerImgObject.nav_level3 = '';

                    tealium.triggerEvent(headerImgObject);
                }
            }
        });

        // Header banner click events
        $('.header-banner .banner-right a').click(function (e) {
            if (window.utag_data) {
                var data = window.utag_data;
                var obj = tealium.checkElement(e);
                var headerBannerObject = initHeaderObject(data);

                headerBannerObject.event_type = 'header_content_click';
                headerBannerObject.nav_click = 'Header:' + obj.label;
                headerBannerObject.element_text = obj.label;
                headerBannerObject.event_message = 'Header:' + obj.label;
                headerBannerObject.ga_label = obj.label;
                headerBannerObject.nav_type = 'header banner';
                headerBannerObject.nav_level1 = obj.label;
                headerBannerObject.nav_level2 = '';
                headerBannerObject.nav_level3 = '';

                tealium.triggerEvent(headerBannerObject);
            }
        });

        // Header logo link click events
        $('.logo-home').click(function () {
            if (window.utag_data) {
                var data = window.utag_data;
                var headerlogoObject = initHeaderObject(data);

                headerlogoObject.page_name = data.page_name;
                headerlogoObject.nav_click = 'NB Logo';
                headerlogoObject.event_message = 'NB Logo';
                headerlogoObject.ga_action = 'NB Logo';
                headerlogoObject.ga_label = '';
                headerlogoObject.element_text = '';
                headerlogoObject.nav_level1 = 'nb logo';
                headerlogoObject.nav_level2 = '';
                headerlogoObject.nav_level3 = '';

                tealium.triggerEvent(headerlogoObject);
            }
        });

        // Login modal open
        $(document).on('login-modal-open', function (event, eventName, section) {
            if (window.utag_data) {
                var data = window.utag_data;
                var checkoutLoginObj = {
                    heirarchy: '',
                    ip_address: data.ip_address,
                    page_name: data.page_name,
                    page_context_type: section,
                    page_context_title: data.page_name,
                    site_section: section,
                    page_section: '',
                    site_sub_section: '',
                    site_sub_section_level3: '',
                    site_sub_section_level4: '',
                    site_sub_section_level5: '',
                    error_text: '',
                    site_type: data.site_type,
                    country: data.country,
                    language: data.language,
                    locale: data.locale,
                    login_type: data.login_type,
                    geoPostal: data.geoPostal,
                    promo_id: data.promo_id,
                    promo_name: data.promo_name,
                    promo_creative: data.promo_creative,
                    promo_position: data.promo_position,
                    order_currency: data.order_currency,
                    user_flow: data.user_flow,
                    viewport: data.viewport,
                    customer_email: data.customer_email,
                    hashed_email: data.hashed_email,
                    user_anonymous: data.user_anonymous,
                    user_authenticated: data.user_authenticated,
                    user_registered: data.user_registered,
                    customer_status: data.customer_status,
                    customer_id: data.customer_id,
                    customer_type: data.customer_type,
                    customer_city: data.customer_city,
                    customer_state: data.customer_state,
                    order_subtotal: data.order_subtotal,
                    cart_order_subtotal: data.cart_order_subtotal,
                    cart_product_name: data.cart_product_name,
                    cart_product_brand: data.cart_product_brand,
                    cart_product_unit_price: data.cart_product_unit_price,
                    cart_product_sku: data.cart_product_sku,
                    cart_product_style_number: data.cart_product_style_number,
                    cart_product_id: data.cart_product_master,
                    adobe_cart_product_id: data.adobe_product_id,
                    cart_product_variant_id: data.cart_product_variant_id,
                    cart_product_master: data.cart_product_master,
                    cart_product_category: data.cart_product_category,
                    cart_product_quantity: data.cart_product_quantity,
                    cart_product_vat: data.cart_product_vat,
                    cart_product_coupon_discount: data.cart_product_coupon_discount,
                    cart_product_coupon_promo: data.cart_product_coupon_promo,
                    cart_product_gender: data.cart_product_gender,
                    cart_product_color: data.cart_product_color,
                    cart_product_size: data.cart_product_size,
                    cart_product_width: data.cart_product_width,
                    cart_product_gbu: data.cart_product_gbu,
                    cart_product_line: data.cart_product_line,
                    cart_product_silhouette: data.cart_product_silhouette,
                    cart_product_type: data.cart_product_type,
                    event_type: eventName,
                    ga_action: 'Login | ' + data.page_name,
                    ga_label: 'Login'
                };
                tealium.triggerEvent(checkoutLoginObj, 'view');
            }
        });

        // Header  cart hover events
        $(document).on('minicartHover', function () {
            if (window.utag_data) {
                var data = window.utag_data;
                var headerMiniCartObject = {
                    heirarchy: '',
                    ip_address: data.ip_address,
                    page_name: data.page_name,
                    page_type: 'mini cart',
                    page_context_type: 'mini cart',
                    page_context_title: 'mini cart',
                    site_section: 'cart',
                    page_section: 'mini cart',
                    site_sub_section: 'mini cart',
                    site_sub_section_level3: '',
                    site_sub_section_level4: '',
                    site_sub_section_level5: '',
                    error_text: '',
                    site_type: data.site_type,
                    country: data.country,
                    language: data.language,
                    locale: data.locale,
                    login_type: data.login_type,
                    geoPostal: data.geoPostal,
                    promo_id: data.promo_id,
                    promo_name: data.promo_name,
                    promo_creative: data.promo_creative,
                    promo_position: data.promo_position,
                    order_currency: data.order_currency,
                    user_flow: '',
                    viewport: data.viewport,
                    customer_email: data.customer_email,
                    hashed_email: data.hashed_email,
                    user_anonymous: data.user_anonymous,
                    user_authenticated: data.user_authenticated,
                    user_registered: data.user_registered,
                    customer_status: data.customer_status,
                    customer_id: data.customer_id,
                    customer_type: data.customer_type,
                    customer_city: data.customer_city,
                    customer_state: data.customer_state,
                    order_subtotal: data.order_subtotal,
                    product_name: data.cart_product_name,
                    product_brand: data.cart_product_brand,
                    product_unit_price: data.cart_product_unit_price,
                    product_sku: data.product_sku,
                    product_style_number: data.cart_product_style_number,
                    product_id: data.cart_product_master,
                    adobe_product_id: data.adobe_product_id,
                    product_master: data.cart_product_master,
                    product_category: data.cart_product_category,
                    product_subcategory: data.cart_product_category,
                    product_quantity: data.cart_product_quantity,
                    product_vat: data.cart_product_vat,
                    product_coupon_discount: data.cart_product_coupon_discount,
                    product_coupon_promo: data.cart_product_coupon_promo,
                    product_gender: data.cart_product_gender,
                    product_color: data.cart_product_color,
                    product_size: data.cart_product_size,
                    product_width: data.cart_product_width,
                    product_gbu: data.cart_product_gbu,
                    product_line: data.cart_product_line,
                    product_type: data.cart_product_type,
                    product_silhouette: data.cart_product_silhouette,
                    empty_cart: data.empty_cart,
                    cart_order_subtotal: data.cart_order_subtotal,
                    cart_product_name: data.cart_product_name,
                    cart_product_brand: data.cart_product_brand,
                    cart_product_unit_price: data.cart_product_unit_price,
                    cart_product_sku: data.cart_product_sku,
                    cart_product_style_number: data.cart_product_style_number,
                    cart_product_id: data.cart_product_master,
                    adobe_cart_product_id: data.adobe_product_id,
                    cart_product_variant_id: data.cart_product_variant_id,
                    cart_product_master: data.cart_product_master,
                    cart_product_category: data.cart_product_category,
                    cart_product_quantity: data.cart_product_quantity,
                    cart_product_vat: data.cart_product_vat,
                    cart_product_coupon_discount: data.cart_product_coupon_discount,
                    cart_product_coupon_promo: data.cart_product_coupon_promo,
                    cart_product_gender: data.cart_product_gender,
                    cart_product_color: data.cart_product_color,
                    cart_product_size: data.cart_product_size,
                    cart_product_width: data.cart_product_width,
                    cart_product_gbu: data.cart_product_gbu,
                    cart_product_line: data.cart_product_line,
                    cart_product_silhouette: data.cart_product_silhouette,
                    cart_product_type: data.cart_product_type,
                    event_type: 'mini_cart',
                    ga_action: 'Open | Mini Cart',
                    ga_label: '',
                    original_product_unit_price: data.original_product_unit_price,
                    category_path: data.category_path
                };
                tealium.triggerEvent(headerMiniCartObject, 'view');
            }
        });

        $(document).on(TRACKING_GLOBAL, '.regions.container .regions li a', function () {
            if (window.utag_data) {
                var countryObj = {
                    event_type: 'select_country',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Select Country | ' + $(this).text(),
                    ga_label: ''
                };
                tealium.triggerEvent(countryObj);
            }
        });

        $(document).on('click', '#email-subscribe-btn', function () {
            var elemText = $(this).text().trim();
            var attrName = $(this)[0].nodeName;
            if (window.utag_data && window.utag_load_data) {
                var loadData = window.utag_load_data;
                var modalObj = {
                    site_section: 'modal',
                    element_type: attrName,
                    element_text: elemText,
                    page_name: loadData.page_name,
                    page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                    event_type: 'email_signup',
                    event_detail: 'modal',
                    page_location: window.location.pathname
                };
                tealium.triggerEvent(modalObj);
            }
        });
    }
};

module.exports = header;
