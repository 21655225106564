/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');
var device = tealium.getDevice();
var utils = require('./utils');

var login = {
    init: function () {
        $('.change-password-form button[name=save]').on('click', function () {
            if (window.utag_data) {
                var savePasswordObj = {
                    event_type: 'my_account_change',
                    change_action: 'edit_password',
                    event_message: 'Edit Password',
                    ga_action: 'Profile',
                    ga_label: 'Edit Password'
                };
                utils.fireTealium(savePasswordObj);
            }
        });

        $(document).on('login:submit', 'form.login, form.login-modal, form.login-embed-checkout, form.login-oauth', function (data) {
            var oauthProvider = data.target.dataset ? data.target.dataset.oauthProvider : null;
            var eventDetail = oauthProvider !== null ? 'sso_' + oauthProvider : 'email_password';
            var loginType = oauthProvider !== null ? 'SFCC_SSO-' + oauthProvider : 'Traditional';
            var isLoginPage = $(this).closest('form').hasClass('login-modal');

            if (window.utag_data) {
                var eventObj = {
                    event_type: isLoginPage ? 'login_overlay' : 'login_top_nav',
                    login_type: loginType,
                    event_detail: eventDetail
                };
                utils.fireTealium(eventObj);
            }
        });

        $(document).on('login:success', function (event, oauthName) {
            var oauthProvider = oauthName || null;
            var eventDetail = oauthProvider !== null ? 'sso_' + oauthProvider : 'email_password';
            var loginType = oauthProvider !== null ? 'SFCC_SSO-' + oauthProvider : 'Traditional';
            if (window.utag_data) {
                var rememberMeVal = document.getElementById('rememberMe') && document.getElementById('rememberMe').checked ? 'yes' : 'no';
                var eventObj = {
                    event_type: 'login_complete',
                    login_type: loginType,
                    remember_me_selected: rememberMeVal,
                    event_detail: eventDetail
                };
                utils.fireTealium(eventObj);
            }
        });

        $(document).on('login:error', function (event, errorMsg, oauthName) {
            var oauthProvider = oauthName || null;
            var eventDetail = oauthProvider !== null ? 'sso_' + oauthProvider : 'email_password';
            var loginType = oauthProvider !== null ? 'SFCC_SSO-' + oauthProvider : 'Traditional';
            if (window.utag_data) {
                var rememberMeVal = document.getElementById('rememberMe') && document.getElementById('rememberMe').checked ? 'yes' : 'no';
                var eventObj = {
                    event_type: 'error_tracking',
                    event_message: 'Login | ' + errorMsg,
                    login_type: loginType,
                    remember_me_selected: rememberMeVal,
                    event_detail: eventDetail
                };
                utils.fireTealium(eventObj);
            }
        });

        $(document).on('login:register:success', 'form.registration, form.registration-modal, form.register-embed-checkout', function () {
            if (window.utag_data) {
                var isCheckboxChecked = false;

                if (document.getElementById('email-subscription')) {
                    isCheckboxChecked = document.getElementById('email-subscription').checked;
                }

                isCheckboxChecked = isCheckboxChecked ? 'yes' : 'no';
                var createAccount = {
                    event_type: 'create_account',
                    login_type: 'create account',
                    event_message: 'login | ' + device,
                    email_signup_checkbox: isCheckboxChecked
                };
                utils.fireTealium(createAccount);
            }
        });

        $('.reset-password-form-login #submitEmailButton').on('click', function () {
            if (window.utag_data) {
                var resetPassword = {
                    event_type: 'my_account_change',
                    change_action: 'reset_password',
                    event_message: 'Reset Password',
                    ga_action: 'Reset Password',
                    ga_label: ''
                };
                utils.fireTealium(resetPassword);
            }
        });
    }
};

login.init();
