'use strict';

const events = require('../util/events');

/**
* Handler for click event of order-status, order-return and faq links from chat modal.
* This function is added into this file because these links are common for ZenDesk and Service Cloud Chat modal,
* Instead of duplicating the code into individual files.
*/
function initTealiumChatModalLinksClickHandler() {
    $('#emeaHelpModal .js-link-order-return, #emeaHelpModal .js-link-order-status, #emeaHelpModal .js-link-faq').on('click', function (e) {
        e.preventDefault();
        var $currentLink = $(e.target);
        /**
         * Callback function to be executed after tealium event is fired.
         */
        var redirectToLink = function () {
            if ($currentLink && $currentLink.attr('href') !== null) {
                window.location = $currentLink.attr('href');
            }
        };
        var trackOrderURL = $('.js-service-cloud-help').data('track-order-url');
        var redirectCallback = trackOrderURL ? function () {
            window.location = trackOrderURL;
        } : redirectToLink;

        if ($currentLink.hasClass('js-link-order-return')) {
            $(document).trigger('tealium:ChatOrderReturnLink', redirectToLink);
        } else if ($currentLink.hasClass('js-link-order-status')) {
            $(document).trigger('tealium:ChatOrderStatusLink', redirectCallback);
        } else if ($currentLink.hasClass('js-link-faq')) {
            $(document).trigger('tealium:ChatFAQLink', redirectToLink);
        }
    });
}

/**
 * OAuth trigger login events
 */
function OAuthLoginInit() {
    // Add listener to SSO Login Buttons
    $('.login-oauth button').on('click', function (e) {
        e.preventDefault();
        var data = this.form.dataset.oauthProvider;
        $(this.form).trigger('login:submit', data);
        $(this).closest('.login-oauth').trigger('submit');
    });

    // Check for Cookie to see SSO login sucess status
    var oauthLoginCookie = document.cookie.split('; ').find((row) => row.startsWith('oauthLoginInfo='))?.split('=')[1];

    if (oauthLoginCookie) {
        var oauthLoginInfoString = JSON.parse(oauthLoginCookie);
        var oauthLoginInfo = JSON.parse(oauthLoginInfoString);

        if (oauthLoginInfo.success) {
            $(document).trigger('login:success', oauthLoginInfo.oauthProvider);
        }
        if (oauthLoginInfo.error) {
            $(document).trigger('login:error', [oauthLoginInfo.error, oauthLoginInfo.oauthProvider]);
        }
        document.cookie = 'oauthLoginInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
}

module.exports = {

    init() {
        document.addEventListener('click', (event) => {
            events.triggerEventDelayed(event.target, events.TRACKING_GLOBAL);
        }, true);
        document.onreadystatechange = function () {
            if (document.readyState === 'complete') {
                OAuthLoginInit();
            }
        };
    },
    initTealiumChatModalLinksClickHandler: initTealiumChatModalLinksClickHandler

};
