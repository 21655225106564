'use strict';

const selectors = {
    userLoggedIn: '.user-logged-in'
};

/**
 * Check if user is logged in.
 * @returns {null|boolean} - Logged in state.
 */
function isLoggedIn() {
    const input = document.querySelector(selectors.userLoggedIn);

    if (!input) {
        return null;
    }

    return input.value === 'true';
}

module.exports = {
    isLoggedIn
};
