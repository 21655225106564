/* eslint-disable no-undef */
/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./pages/search'));
    processInclude(require('./pages/filter'));
    processInclude(require('./pages/header'));
    processInclude(require('./pages/footer'));
    processInclude(require('./pages/productlisting'));
    processInclude(require('./pages/login'));
    processInclude(require('./pages/orderStatus'));
    processInclude(require('./pages/cartEvents'));
    processInclude(require('./pages/productDetails'));
    processInclude(require('./pages/wishlist'));
    processInclude(require('./pages/tealium'));
    processInclude(require('./pages/utils'));
    processInclude(require('./pages/editProfile'));
    processInclude(require('./pages/address'));
    processInclude(require('./pages/rewards'));
    processInclude(require('./pages/orderHistory'));
    processInclude(require('./pages/returns'));
    processInclude(require('./pages/checkout'));
    processInclude(require('./pages/cookieConsent'));
    processInclude(require('./pages/digitalretail'));
    processInclude(require('./pages/productPage'));
    processInclude(require('./pages/arTryOn'));
    processInclude(require('./pages/experience'));
    processInclude(require('./pages/addressVerification'));
    processInclude(require('./pages/sortBy'));
    processInclude(require('./changeCountry/countryCheck'));
    processInclude(require('./chat/chat'));
    processInclude(require('./wishlist/wishlist'));
    processInclude(require('./recommendationCarousel/recommendationCarousel'));
    processInclude(require('./pages/stylitics'));
    processInclude(require('./account/accountVerification'));
});
