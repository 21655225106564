/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');
var TRACKING_ORDERHISTORY = 'tracking:orderhistory';

var orderHistory = {
    init: function () {
        $(document).on(TRACKING_ORDERHISTORY, '.order-details .order-cancel-modal-open', function () {
            if (window.utag_data) {
                var cancelOrderObj = {
                    event_type: 'cancel_order_start',
                    page_name: window.utag_data.page_name,
                    event_message: 'Cancel Order Start',
                    ga_action: 'Order Change',
                    ga_label: 'Cancel Start'
                };
                tealium.triggerEvent(cancelOrderObj);
            }
        });

        $('.remove-order-confirmation').on('orderhistory:cancel', function (event, data) {
            if (!window.utag_data) {
                return;
            }

            var cancelOrderFinalObj = {
                event_type: 'cancel_order_finish',
                page_name: window.utag_data.page_name,
                event_message: 'Cancel Order Yes | ' + data,
                ga_action: 'Order Change',
                ga_label: 'Cancel End'
            };
            tealium.triggerEvent(cancelOrderFinalObj);
        });

        $(document).on('orderhistory:removeProduct', function () {
            if (!window.utag_data) {
                return;
            }

            var removeObj = {
                event_type: 'history_remove',
                page_name: window.utag_data.page_name,
                event_message: 'Order History Remove',
                ga_action: 'Order Change',
                ga_label: 'Remove'
            };
            tealium.triggerEvent(removeObj);
        });

        $(document).on(TRACKING_ORDERHISTORY, '.order__history .edit-order', function () {
            if (window.utag_data) {
                var editObj = {
                    event_type: 'edit_order',
                    page_name: window.utag_data.page_name,
                    event_message: 'Edit Order',
                    ga_action: 'Order Change',
                    ga_label: 'Edit'
                };
                tealium.triggerEvent(editObj);
            }
        });

        $(document).on(TRACKING_ORDERHISTORY, '.buy-button .buy-again', function () {
            if (window.utag_data) {
                var buyAgainObj = {
                    event_type: 'buy_again',
                    page_name: window.utag_data.page_name,
                    event_message: 'Buy Again',
                    ga_action: 'Order Change',
                    ga_label: 'Buy Again'
                };
                tealium.triggerEvent(buyAgainObj);
            }
        });

        $(document).on(TRACKING_ORDERHISTORY, '.order-details .start-return', function () {
            if (window.utag_data) {
                var startReturn = {
                    event_type: 'start_return',
                    page_name: window.utag_data.page_name,
                    event_message: 'Start Return',
                    ga_action: 'Order Change',
                    ga_label: 'Return'
                };
                tealium.triggerEvent(startReturn);
            }
        });
    }
};

orderHistory.init();
