/* eslint require-jsdoc: "off", curly: "error" */

'use strict';

function handleSuccessMessage(form) {
    form.parents('.email-signup-component').find('.email-signup-form-success').removeClass('d-none');
    form.parents('.email-signup-component').find('.email-signup-form').addClass('d-none');
}

function pageDesignerEmailInit() {
    const $form = $('.email-subscribe-pd-form:not([data-scripted])');

    if ($form.length === 0) {
        return;
    }

    $form.attr('data-scripted', 'true');
    $form.submit(function (e) {
        var masterID = $(this).closest('.email-signup-component').data('master-id');
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        var emailVal = form.find('.form-email').val();
        if (window.utag_data) {
            if (masterID !== '') {
                $(document).trigger('emailsignupcomponent', masterID);
            } else {
                $(document).trigger('discount:emailsubscribe');
            }
        }
        var source = form.find('.email-acq-source').val();
        form.spinner().start();
        var reqObj = {
            email: emailVal,
            source: source
        };
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: reqObj,
            success: function (data) {
                form.spinner().stop();
                if (data.success) {
                    handleSuccessMessage(form);
                }
            },
            error: function (err) {
                form.spinner().stop();
                throw err;
            }
        });
        return false;
    });
}

$(document).ready(function () {
    pageDesignerEmailInit();
    document.addEventListener('content:updated', pageDesignerEmailInit);
});
