/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable require-jsdoc */
/* eslint-disable eqeqeq */
/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var focusStart = false;
var search = {
    init: function () {
        function suggetionTile() {
            var searchTerm = $('.site-search').find('input')[0].value;
            var suggestionDataObject = {
                event_type: 'enhancedSearch_productResults',
                page_name: window.utag_data.page_name,
                event_message: 'Enhanced Search | Product Result | ' + searchTerm,
                search_term: searchTerm,
                ga_label: searchTerm,
                ga_action: 'Product Results',
                enhanched_search_click: searchTerm
            };
            return suggestionDataObject;
        }
        // Search product tile click event
        $('.product-items img').click(function () {
            if (window.utag_data) {
                var suggestionDataImgObject = suggetionTile();
                tealium.triggerEvent(suggestionDataImgObject);
            }
        });
        $('.product-items .pdp-link').click(function () {
            if (window.utag_data) {
                var suggestionDataLinkObject = suggetionTile();
                tealium.triggerEvent(suggestionDataLinkObject);
            }
        });

        // Top Search result
        $('.tl_top_searches a, .custom-top-searches a').click(function (e) {
            if (window.utag_data) {
                var suggestionDataLinkObject = suggetionTile();
                suggestionDataLinkObject.event_type = 'enhancedSearch_topCategories';
                suggestionDataLinkObject.ga_action = 'Top Categories';
                suggestionDataLinkObject.event_message = 'Enhanced Search | Top Categories | ' + $(e.target).text().trim();
                tealium.triggerEvent(suggestionDataLinkObject);
            }
        });

        // Top recent Search result
        $('.tl_recent_searches a').click(function (e) {
            if (window.utag_data) {
                var suggestionDataLinkObject = suggetionTile();
                suggestionDataLinkObject.event_type = 'enhancedSearch_recentSearches';
                suggestionDataLinkObject.ga_action = 'Recent Searches';
                suggestionDataLinkObject.event_message = 'Enhanced Search | Recent Searches | ' + $(e.target).text().trim();
                tealium.triggerEvent(suggestionDataLinkObject);
            }
        });

        // Top clicked result
        $('.tl_search_recent_click a').click(function (e) {
            if (window.utag_data) {
                var suggestionDataLinkObject = suggetionTile();
                suggestionDataLinkObject.event_type = 'enhancedSearch_results_click';
                suggestionDataLinkObject.ga_action = 'Clicked Result';
                suggestionDataLinkObject.event_message = 'Enhanced Search | Clicked Result | ' + $(e.target).text().trim();
                tealium.triggerEvent(suggestionDataLinkObject);
            }
        });

        // Did you mean event
        $('.suggestions .search-phrase a').click(function () {
            if (window.utag_data) {
                var searchTitle = $('.site-search').find('input')[0].value;
                // Search Did you mean click event
                var suggestionDataObject = {
                    event_type: 'enhancedSearch_didyouMean',
                    page_name: window.utag_data.page_name,
                    event_message: 'Enhanced Search | didyouMean | ' + searchTitle,
                    enhanched_search_click: searchTitle,
                    search_term: searchTitle,
                    ga_action: 'Suggested Search Term',
                    ga_label: searchTitle
                };
                tealium.triggerEvent(suggestionDataObject);
            }
        });

        // Close the module
        $('.suggestions .close').click(function () {
            focusStart = false;
            if (window.utag_data) {
                // Search close click event
                var closeSuggestionObject = {
                    event_type: 'enhancedSearch_close',
                    page_name: window.utag_data.page_name,
                    enhanched_search_click: 'Close',
                    search_term: $('.site-search').find('input')[0].value,
                    ga_action: 'Exit Button',
                    ga_label: ''
                };
                tealium.triggerEvent(closeSuggestionObject);
            }
        });

        // Search see result click event
        $('.see-all').click(function () {
            if (window.utag_data) {
                var searchTitle = $('.site-search').find('input')[0].value;
                var seeResultData = {
                    event_type: 'enhancedSearch_viewAll',
                    page_name: window.utag_data.page_name,
                    event_message: 'Enhanced Search | View All | ' + searchTitle,
                    enhanched_search_click: searchTitle,
                    search_term: searchTitle,
                    ga_action: 'View All',
                    ga_label: searchTitle
                };
                tealium.triggerEvent(seeResultData);
            }
        });
    }

};

// On Ajax request completed wait for 2 seconds for initialization of DOM
window.suggetionBoxDomReady = function () {
    setTimeout(function () {
        search.init();
    }, 1000);
};

// Focus search field.
$('.tl_site_search').on('focus', function () {
    if (window.utag_data && !focusStart) {
        focusStart = true;
        var startDataObject = {
            event_type: 'enhancedSearch_start',
            page_name: window.utag_data.page_name,
            event_message: 'enhancedSearch_start',
            enhanched_search_click: $('.site-search').find('input')[0].value,
            search_term: $('.site-search').find('input')[0].value,
            ga_action: 'Open Search',
            ga_label: ''
        };
        tealium.triggerEvent(startDataObject);
    }
});
