'use strict';

/**
 * Prefetch the assets.
 */
function prefetch() {
    const element = document.querySelector('.js-prefetch-assets');
    const json = element ? element.getAttribute('data-prefetch-assets') : null;

    if (!json) {
        return;
    }

    let list = null;

    try {
        list = JSON.parse(json);
    } catch (e) {
        if (window.console) {
            window.console.warn('Error on parsing prefetch assets list');
        }
    }

    if (!list || !Array.isArray(list)) {
        return;
    }

    // Safari does nothing with prefetch links, so on Safari preload is used instead.
    const rel = /apple/i.test(navigator.vendor || '') ? 'preload' : 'prefetch';

    for (let i = 0; i < list.length; i += 1) {
        const asset = list[i];

        if (asset && asset.url && asset.type) {
            setTimeout(() => {
                const link = document.createElement('link');
                link.onload = () => {
                    link.onload = null;
                    link.setAttribute('data-loaded', 'true');
                };
                link.href = asset.url;
                link.rel = rel;
                link.as = asset.type;
                document.head.appendChild(link);
            }, Math.max(i * 500, 5));
        }
    }
}

module.exports = {
    init() {
        window.addEventListener('load', () => {
            setTimeout(prefetch, 250);
        });
    }
};
