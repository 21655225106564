'use strict';

/**
 * Custom element that loads external content.
 */
class ContentLoader extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('content-loader')) {
            return;
        }

        window.customElements.define('content-loader', ContentLoader);
    }

    constructor() {
        super();

        this.url = null;
        this.loadEvent = null;
    }

    connectedCallback() {
        this.url = this.getAttribute('url');
        this.loadEvent = this.getAttribute('load-event');
        this.timeout = parseInt(this.getAttribute('timeout'), 10) || null;

        if (!this.url) {
            return;
        }

        if (this.timeout) {
            setTimeout(this.loadContent.bind(this), this.timeout);
        } else {
            this.loadContent();
        }
    }

    loadContent() {
        window.fetch(this.url, {
            method: 'GET',
            credentials: 'include'
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(`Status ${response.status} on response`);
            } else {
                return response.text();
            }
        }).then((html) => {
            this.handleLoad(html);
        }).catch((error) => {
            this.handleLoadError(error);
        });
    }

    handleLoad(responseText) {
        // Some script must have removed the content loader.
        if (!this.parentNode) {
            return;
        }

        // Insert HTML just before loader element.
        const div = document.createElement('div');
        div.innerHTML = responseText;

        while (div.hasChildNodes()) {
            this.parentNode.insertBefore(div.firstChild, this);
        }

        // Dispatch load event when relevant.
        if (this.loadEvent) {
            const event = new CustomEvent(this.loadEvent, {
                bubbles: true
            });

            this.parentNode.dispatchEvent(event);
        }

        // Remove loader element.
        this.remove();
    }

    handleLoadError(error) {
        // Log error.
        if (window.console) {
            window.console.error(`content-loader error for ${this.url}:`, error);
        }

        // Remove loader element.
        this.remove();
    }
}

module.exports = ContentLoader;
