'use strict';

var closeDialogsWithEsc = function () {
    document.addEventListener('keydown', (e) => {
        const dialog = document.querySelectorAll('[role="dialog"]');

        // esc to close open dialogs
        if (e.key === 'Escape' || e.code === 'Escape') {
            for (let i = 0; i < dialog.length; i += 1) {
                if (window.getComputedStyle(dialog[i]).display === 'block' && !dialog[i].classList.contains('hidden-slider')) {
                    const iconClose = dialog[i].querySelector('.js-closeButton');
                    if (iconClose) {
                        iconClose.click();
                    } else {
                        dialog[i].display = 'none';
                    }
                    document.activeElement.focus();
                }
            }
        }
    });
};

var trapFocus = function (container, lastActiveElement) {
    if (container && lastActiveElement) {
        // eslint-disable-next-line max-len
        let focusableElements = container.querySelectorAll('button:not([tabindex="-1"]), a:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])');

        if (container.querySelector('.js-closeButton')) {
            container.querySelector('.js-closeButton').focus();
        } else {
            focusableElements[0].focus();
        }

        // eslint-disable-next-line consistent-return
        const handleTrapFocus = function (e) {
            const isTabPressed = (e.key === 'Tab' || e.code === 'Tab');

            // esc to close
            if (e.key === 'Escape' || e.keyCode === 27) {
                container.removeEventListener('keydown', handleTrapFocus);
                lastActiveElement.focus();
            } else if (!isTabPressed) {
                return false;
            }

            if (e.shiftKey) {
                if (document.activeElement === focusableElements[0]) {
                    focusableElements[focusableElements.length - 1].focus();
                    e.preventDefault();
                }
            } else if (document.activeElement === focusableElements[focusableElements.length - 1]) {
                focusableElements[0].focus();
                e.preventDefault();
            }
        };

        container.addEventListener('keydown', handleTrapFocus);
    }
};

var toggleAriaExpanded = function (expandedElement) {
    if (expandedElement) {
        // eslint-disable-next-line eqeqeq
        let newState = expandedElement.getAttribute('aria-expanded') != 'true';
        let controlElements = document.querySelectorAll('[aria-controls="' + expandedElement.id + '"]');
        controlElements.forEach(element => {
            element.setAttribute('aria-expanded', newState);
        });
        expandedElement.setAttribute('aria-expanded', newState);
    }
};

module.exports = {
    closeDialogsWithEsc: closeDialogsWithEsc,
    trapFocus: trapFocus,
    toggleAriaExpanded: toggleAriaExpanded
};
