/* eslint-disable no-undef */

'use strict';

var tealium = require('../pages/tealium');
var utils = require('../pages/utils');

var wishlist = {
    init: function () {
        $(document).on('wishlist:add-product', function (_, data) {
            if (window.utag_data && data.product_data) {
                var baseData = utils.getCommonUtagProductData(data.product_data);
                var siteData = utils.getCommonUtagSiteData(window.utag_data);

                var buttonData = {
                    event_type: data.event_type,
                    ga_action: data.ga_action,
                    ga_label: data.ga_label,
                    element_text: data.element_text,
                    element_type: data.element_type
                };

                var wishlistObj = Object.assign(baseData, siteData, buttonData);

                tealium.triggerEvent(wishlistObj);
            }
        });
    }
};

wishlist.init();
