'use strict';

var utils = require('../utils');

/**
 * Method to return prefix value
 * @returns {string} prefix value
 */
function getPrefix() {
    var $page = $('div.page');
    var prefix = 'pc-other-';
    if ($page && $page.length) {
        var pageType = $page.data().action;
        if (pageType === 'Product-Show') {
            prefix = 'pc-pdp-';
        }
    }
    return prefix;
}

/**
 * Returns an array of selected dom elements
 * @returns {Array} returns an htmlcollection
 */
function getSelectedDomElements() {
    var selectTagElements = document.getElementsByClassName('comparison-select');
    var selectedElements = [];

    Array.from(selectTagElements).forEach(function (selectTag) {
        var selectedElem = {};
        if (selectTag.selectedIndex > 0) {
            selectedElem.selectTag = selectTag.item(selectTag.selectedIndex);
            selectedElem.name = selectTag.item(selectTag.selectedIndex).text;
            selectedElem.pid = selectTag.item(selectTag.selectedIndex).dataset.pid;
            selectedElem.index = selectTag.selectedIndex;
            selectedElem.tile = selectTag.parentElement.className;
            selectedElements.push(selectedElem);
        }
    });

    return selectedElements;
}

/**
 * Restores previously hidden options from select dropdowns
 * @param {Array} selectTagElements contains select dropdowm DOM elements
 */
function restoreOptions(selectTagElements) {
    for (var i = 0; i < selectTagElements.length; i += 1) {
        var selectTag = selectTagElements[i];

        for (let j = 0; j < selectTag.options.length; j += 1) {
            var option = selectTag.options[j];
            var $optionElement = $(option);
            if ($optionElement && $optionElement.length && $optionElement.is(':disabled')) {
                $optionElement.prop('disabled', false);
            }
        }
    }
}

/**
 * Hides options from select dropdowns
 * @param {Array} selectTagElements contains select dropdowm DOM elements
 * @param {Array} selectedItems contains an array of selected item objects
 */
function hideOptions(selectTagElements, selectedItems) {
    for (var i = 0; i < selectTagElements.length; i += 1) {
        var selectTag = selectTagElements[i];

        for (let j = 0; j < selectedItems.length; j += 1) {
            var selectedItem = selectedItems[j];
            var option = selectTag.item(selectedItem.index);
            var $optionElement = $(option);
            if ($optionElement && $optionElement.length && !$optionElement.is(':disabled')) {
                $optionElement.prop('disabled', true);
            }
        }
    }
}

/**
 * Updates the available products in select dropdown within the comparison tiles
 */
function updateSelectOptions() {
    var selectTagElements = document.getElementsByClassName('comparison-select');
    // Get all currently selected product indetifiers from DOM
    var selectedItems = getSelectedDomElements();

    // Restore previosly hidden options
    restoreOptions(selectTagElements);

    // Hide the selected options from the dropdowns
    hideOptions(selectTagElements, selectedItems);
}

/**
 * Method to update comparison select
 * @param {Object} data placeholder for object holding details like productContainer, pids value
 */
function updateComparisonSelect(data) {
    var $productContainer = data.productContainer;
    var tile = data.tile;
    var pid = data.pid;
    var $comparisonSelectElement = $($productContainer).find('.product-detail-select.product-item-' + tile + ' select');
    if ($comparisonSelectElement && $comparisonSelectElement.length) {
        var optionFound = $($comparisonSelectElement).find('[data-pid|=' + pid + ']');
        if (optionFound && optionFound.length) {
            $comparisonSelectElement.val(optionFound.val());
        }
    }
}
/**
 * Method to update comparison tile
 * @param {Object} data placeholder for object holding details like productContainer, tile, html value
 */
function updateComparisonTile(data) {
    var $productContainer = data.productContainer;
    var tile = data.tile;
    var htmlContent = data.html;
    var $comparisonTileElement = $($productContainer).find('.product-detail-item.product-item-' + tile);
    if ($comparisonTileElement && $comparisonTileElement.length) {
        $comparisonTileElement.addClass('no-dash');
        var $comparisonTile = $comparisonTileElement.find('.comparison-tile');
        if ($comparisonTile && !$comparisonTile.length) {
            $comparisonTile = $comparisonTileElement.find('.empty-panel');
        }
        $comparisonTile.replaceWith($.parseHTML(htmlContent));

        // Update the options in the select dropdowns
        updateSelectOptions();
    }
}
/**
 * Method to return pid for PDP pages
 * @returns {string} identifier value
 */
function getIdentifier() {
    var identifier;
    var $wrapper = document.querySelector("meta[name='CanonicalPageName']");
    if ($wrapper && $wrapper.content) {
        identifier = $wrapper.content;
    } else {
        $wrapper = $('.container.product-detail.product-wrapper.product-comparison-container[data-pageid]');
        if ($wrapper && $wrapper.length) {
            identifier = $wrapper.data().pageid;
        }
    }

    if (identifier) {
        var prefix = getPrefix();
        identifier = prefix + identifier;
    }

    return identifier;
}
/**
 * Method to get pid and tile number
 * @param {string} url url value
 * @return {Object} placeholder for pid and tile value
 */
function getPIDTileInfo(url) {
    var pidStr = 'pid=';
    var prdTileVal = url.substring(url.indexOf(pidStr) + pidStr.length);
    prdTileVal = decodeURIComponent(prdTileVal);
    var prdTile = prdTileVal.split('|');
    if (prdTile && prdTile.length) {
        return {
            pid: prdTile[1],
            tile: prdTile[0],
            value: prdTileVal
        };
    }
    return null;
}

/**
 * Method to define the cookie updated value
 * @param {string} cookie placeholder for cookie string value
 * @param {Object} pidData placeholder for pid, tile, value
 * @return {string} updated cookie value
 */
function defineCookieValue(cookie, pidData) {
    var found = false;
    var cookirArr = cookie.split(',');
    for (var i = 0; i < cookirArr.length; i += 1) {
        if (cookirArr[i].indexOf(pidData.tile + '|') >= 0) {
            found = true;
            cookirArr[i] = pidData.value;
        }
    }
    if (!found) {
        cookirArr.push(pidData.value);
    }
    return cookirArr.toString();
}

/**
 * Method to update cookie object
 * @param {string} url url value
 * @param {boolean} onLoad true on page load, false on user selection
 * @return {Object} placeholder for pids and value
 */
function updateCookie(url, onLoad) {
    var cookie;
    var cookieID = getIdentifier();
    var pidData = getPIDTileInfo(url);
    if (pidData) {
        cookie = $.cookie(cookieID);
        if (cookie && cookie.indexOf(pidData.pid) < 0) {
            $.cookie(cookieID, defineCookieValue(cookie, pidData), { expires: 1, path: '/' });
        } else if (!cookie) {
            $.cookie(cookieID, pidData.value, { expires: 1, path: '/' });
        }
    }
    if (onLoad) {
        cookie = $.cookie(cookieID);
    } else {
        cookie = pidData.value;
    }

    return {
        isMulti: (onLoad && cookie.split(',').length > 1),
        value: cookie
    };
}
/**
 * Method to select product to compare
 * @param {string} selectedValueUrl placeholder for url value
 * @param {string} $productContainer placeholder for product container object
 * @param {boolean} onLoad true on page load, false on user selection
 */
function selectProductToCompare(selectedValueUrl, $productContainer, onLoad) {
    var $node = $('.product-detail-item');
    var urlValue = selectedValueUrl;
    var cookieData = updateCookie(selectedValueUrl, onLoad);
    if (cookieData && cookieData.isMulti) {
        urlValue = urlValue.substring(0, urlValue.indexOf('?')).concat('?pid=' + cookieData.value);
    } else {
        var decodedUrl = decodeURIComponent(selectedValueUrl);
        var tile = decodedUrl.substring(decodedUrl.indexOf('pid=') + 4, decodedUrl.indexOf('|'));
        $node = $('.product-detail-item.product-item-' + tile);
    }
    $node.spinner().start();
    $.ajax({
        url: urlValue,
        method: 'GET',
        success: function (data) {
            if (data.tiles && data.tiles.length
                && data.pids && data.pids.length
                && data.htmls && data.htmls.length) {
                for (var i = 0; i < data.tiles.length; i += 1) {
                    updateComparisonSelect({
                        productContainer: $productContainer,
                        tile: data.tiles[i],
                        pid: data.pids[i]
                    });
                    updateComparisonTile({
                        productContainer: $productContainer,
                        tile: data.tiles[i],
                        html: data.htmls[i]
                    });
                }
            }
            $node.spinner().stop();
        },
        error: function () {
            $node.spinner().stop();
        }
    });
}

/**
 * Method to get pid preselected w.r.t tile
 * @returns {string} product id
 */
function getPreSelectedPID() {
    var pid = getIdentifier();
    var cookie = $.cookie(pid);
    if (cookie) {
        var firstId = cookie.indexOf('1|');
        if (firstId > -1) {
            var comma = cookie.indexOf(',');
            pid = cookie.substring(firstId + 2, (comma < 0) ? cookie.length : comma);
        }
    }
    return pid;
}
/**
 * Method to get updated URL with product ids and their respective tile number values
 * @returns {string} url value
 */
function getURLUpdate() {
    var urlValue;
    var pids;
    var productIds = $('.product-detail-select.product-item-1 option[data-pid]');
    var foundOption = $(productIds[0]);
    var noOfTiles = utils.isMobile() ? 1 : 3;
    if (productIds.length >= 3 && noOfTiles === 3) {
        for (var i = 0; i < noOfTiles; i += 1) {
            pids = (i === 0) ? (i + 1) + '|' + $(productIds[i]).data('pid') : pids + ',' + (i + 1) + '|' + $(productIds[i]).data('pid');
        }
    }
    urlValue = foundOption.val();
    if (pids && pids.length) {
        urlValue = urlValue.substring(0, urlValue.indexOf('pid=') + 4) + pids;
    }
    return urlValue;
}
/**
 * Method to load product details in comparison tile
 * @param {string} pid placeholder for product id
 */
function loadProduct(pid) {
    var element = $('.product-detail-select.product-item-1 select');

    if (element && element.length) {
        var $productContainer;
        var prefix = new RegExp(getPrefix(), 'g');
        var foundOption = $('.product-detail-select.product-item-1 select [data-pid|=' + pid.replace(prefix, '') + ']');
        if (foundOption && foundOption.length) {
            element.val(foundOption.val());
            $productContainer = element.closest('.product-comparison-container');
            if ($productContainer && $productContainer.length) {
                selectProductToCompare(foundOption.val(), $productContainer, true);
            }
        } else {
            var urlValue = getURLUpdate();
            if (urlValue) {
                element = $('.product-detail-select.product-item-1 select');
                $productContainer = element.closest('.product-comparison-container');
                if ($productContainer && $productContainer.length) {
                    selectProductToCompare(urlValue, $productContainer, true);
                }
            }
        }
    }
}
/**
 * Method to initiate product container loading
 * @param {Object} $productComparison placeholder for productContainer object
 */
function initiateProductLoading($productComparison) {
    var contentFound = false;
    if (!contentFound && $productComparison && $productComparison.length) {
        var pid = getPreSelectedPID();
        if (pid) {
            contentFound = true;
            loadProduct(pid);
        }
    }
}
/**
 * The method gets the PID of the current PDP
 * The method listens to a custom content event
 * Once the event fires, find the first select and set to PID
 */
function setDefaultProductComparison() {
    var $productComparison = $('.product-comparison-container');
    if ($productComparison) {
        if (!$productComparison.length) {
            $(document).on('content:updated', () => {
                $productComparison = $('.product-comparison-container');
                initiateProductLoading($productComparison);
            });
        } else {
            initiateProductLoading($productComparison);
        }
    }
}

module.exports = {
    selectToCompare: function () {
        $(document).off('change', '.comparison-select').on('change', '.comparison-select', function (e) {
            e.preventDefault();
            var selectedValueUrl = e.currentTarget.value;
            if (selectedValueUrl) {
                var $productContainer = $(this).closest('.product-comparison-container');
                if ($productContainer && $productContainer.length) {
                    selectProductToCompare(selectedValueUrl, $productContainer, false);
                }
                // Track dropdown selection
                var elem = $(e.currentTarget.options[e.currentTarget.selectedIndex]);
                var pid = elem.data('pid');
                if (window.utag_data) {
                    $(document).trigger('productcompare:dropdown', pid);
                }
            }
        });
    },
    comparisonToolTip: function () {
        $(document).on('touchstart', '.product-comparison-container .right-panel', function (event) {
            var elem = $(event.currentTarget);
            elem.find('.t-tip').toggleClass('d-flex');
        });

        $(document).on('mouseenter', '.product-comparison-container .right-panel', function (event) {
            var elem = $(event.currentTarget);
            elem.find('.t-tip').addClass('d-flex');
        });

        $(document).on('mouseleave', '.product-comparison-container .right-panel', function (event) {
            var elem = $(event.currentTarget);
            elem.find('.t-tip').removeClass('d-flex');
        });
    },
    tracking: function () {
        $(document).on('click', '.product-image > a', function (event) {
            var elem = $(event.currentTarget);
            var pid = elem.closest('.comparison-tile').data('pid');

            if (window.utag_data) {
                $(document).trigger('productcompare:image', pid);
            }
        });

        $(document).on('click', '.product-shop-now > a', function (event) {
            var elem = $(event.currentTarget);
            var pid = elem.closest('.comparison-tile').data('pid');

            if (window.utag_data) {
                $(document).trigger('productcompare:shopnow', pid);
            }
        });
    }
};

$(document).ready(function () {
    setDefaultProductComparison();
});

$(window).on('load', function () {
    // Track component load event
    if (window.utag_data && $('.product-comparison-container').length > 0) {
        var selectedProducts = getSelectedDomElements();
        selectedProducts = selectedProducts.length > 4 ? selectedProducts.slice(0, 4) : selectedProducts;
        var selectedOptions = JSON.stringify(selectedProducts);
        $(document).trigger('productcompare:impression', selectedOptions);
    }
});
