/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');

module.exports = {
    init: function () {
        $(document).on('find:order', function (i, orderNumber) {
            if (window.utag_data) {
                tealium.tealiumHelper('find_order', window.utag_data.page_name, 'Return | Find Order', 'Return | Find Order', orderNumber);
            }
        });

        $(document).on('return:item', function (i, productId) {
            if (window.utag_data) {
                tealium.tealiumHelper('return_item', window.utag_data.page_name, 'Return | Select Item', 'Return | Select Item', productId);
            }
        });

        $(document).on('select-reason:change', function (i, dropdownValue) {
            if (window.utag_data) {
                tealium.tealiumHelper('return_reason', window.utag_data.page_name, 'Return | Reason for Return', 'Return | Reason for Return', dropdownValue);
            }
        });

        $(document).on('submit:email', function () {
            if (window.utag_data) {
                tealium.tealiumHelper('email_label', window.utag_data.page_name, 'Return | Label', 'Return | Label', 'Email');
            }
        });

        $(document).on('print:label', function () {
            if (window.utag_data) {
                tealium.tealiumHelper('print_label', window.utag_data.page_name, 'Return | Label', 'Return | Label', 'Print');
            }
        });

        $(document).on('return-type:change', function (i, type) {
            if (window.utag_data) {
                tealium.tealiumHelper('return_type', window.utag_data.page_name, 'Return | Return Type', 'Return | Reason Return Type', type);
            }
        });
    }
};
