'use strict';

const events = require('../util/events');

(function () {
    // Avoid showing change country overlay in PD edit surroundings.
    if (window.top !== window.self) {
        return;
    }

    var siteSelection = $.cookie('SiteSelection');
    var changeCountryContainer = $('#change-country-container');

    // Server did not output content, because SiteSelection cookie matches site country.
    if (changeCountryContainer.length === 0) {
        return;
    }

    var geoCountry = changeCountryContainer.data('geocountry');
    var siteCountry = changeCountryContainer.data('country-code');
    var expires = changeCountryContainer.data('int-exp');

    var setCountryCookie = function (selectedCountry, href) {
        $.cookie('SiteSelection', selectedCountry, { expires: (expires ? parseInt(expires, 10) : 1), path: '/' });
        if (href) {
            window.location = href;
        }
    };

    var selectRegionEvent = function (element, previous, selected) {
        $(document).trigger('preferences:select-region', {
            previous_country: previous,
            selected_country: selected,
            element_type: element[0].nodeName,
            element_text: element[0].textContent
        });
    };

    // Check if geolocation country and site country are the same.
    if (siteCountry !== geoCountry) {
        // If not, check if previous user selection matches site country anyway.
        if (siteSelection !== siteCountry) {
            // If both no, show country modal.
            var changeCountryModal = $('#change-country-modal');

            if (changeCountryModal.length) {
                // Show change counry modal
                changeCountryModal.modal('show');

                // Set SiteSelection as geoCountry and navigate to suggested site
                changeCountryModal.find('#suggested-country').on('click', function () {
                    selectRegionEvent($(this), siteCountry, geoCountry);
                    setCountryCookie(geoCountry, this.href);
                });

                // Set SiteSelection as siteCountry and dismiss the modal
                changeCountryModal.find('#continue-country').on('click', function (event) {
                    selectRegionEvent($(this), siteCountry, siteCountry);
                    setCountryCookie(siteCountry);
                    changeCountryModal.modal('hide');
                    events.triggerEventDelayed(event.target, events.TRACKING_FOOTER);
                });
            }
        }

        // SiteSelection as current site country.
        setCountryCookie(siteCountry);
    }

    /**
     * Constructs a URL by concatenating the domain and path, ensuring there are no duplicate slashes.
     *
     * @param {string} domain - The base domain URL. Example: 'https://www.newbalance.com/'
     * @param {string} path - The path to be appended to the domain. Can be empty or start with a '/'. Example: '/on/demandware.store/Sites-BANG-Site/nl_NL/Login-Show'
     * @return {string} The concatenated URL without duplicate slashes. If the domain is empty, returns an empty string.
     */
    function buildUrl(domain, path) {
        if (!domain) return '';

        // Remove a trailing slash from the domain if it exists
        if (domain.endsWith('/') && path) {
            // eslint-disable-next-line no-param-reassign
            domain = domain.slice(0, -1);
        }

        // Ensure the path starts with a single slash if it's not empty
        if (path && !path.startsWith('/')) {
            // eslint-disable-next-line no-param-reassign
            path = '/' + path;
        }

        return domain + path;
    }

    $(document).on('click', '#suggested-country', function () {
        var $this = $(this);
        var domain = $this.data('domain');
        var path = $this.data('path');
        $(document).trigger('country:suggested', $this.data('code'));

        var url = buildUrl(domain, path);

        if (url) {
            window.location.href = url;
        } else {
            window.location.reload();
        }
    });
}());
