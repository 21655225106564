'use strict';

const events = {
    triggerEvent(target, name, data) {
        const event = new CustomEvent(name, {
            bubbles: true,
            detail: data || {}
        });
        target.dispatchEvent(event);
    },

    triggerEventDelayed(target, name, data, timeout = 3) {
        setTimeout(() => {
            this.triggerEvent(target, name, data);
        }, timeout);
    },

    // Tracking events.
    TRACKING_GLOBAL: 'tracking:global',
    TRACKING_MINICART: 'tracking:minicart',
    TRACKING_DIGITALRETAIL: 'tracking:digitalretail',
    TRACKING_FOOTER: 'tracking:footer',
    TRACKING_PRODUCTDETAIL: 'tracking:productdetail',
    TRACKING_WISHLIST: 'tracking:wishlist',
    TRACKING_ORDERHISTORY: 'tracking:orderhistory',
    DEFAULT_DELAY: 3
};

module.exports = events;
