'use strict';

/**
 * Get the cookie
 * @param {string} cname - a string representing name of the cookie
 * @return {string} - cookie string
 */
function getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    var i;
    var c;
    for (i = 0; i < ca.length; i += 1) {
        c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

/**
 * Set the cookie
 * @param {string} cname - a string representing name of the cookie
 * @param {string} cvalue - a string representing the value of the cookie
 * @param {number} exdays - a string representing the expiry
 */
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    var expires;
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = 'expires=' + d.toGMTString();
    document.cookie = cname + '=' + cvalue + '; path=/; ' + expires;
}

/**
 *  Checks the cookie
 */
function checkCookie() {
    if (navigator.cookieEnabled) {
        var hideEmailForm = getCookie('hideEmail');
        if (hideEmailForm !== 'hide') {
            setCookie('hideEmail', 'hide', 365);
        }
    }
}

/**
 * @return {number} - It returns pages visit count
 */
function checkPageVisitedWithinWebsite() {
    var pageCount = window.localStorage.getItem('pageVisitCount') ? window.localStorage.getItem('pageVisitCount') : 0;
    pageCount = Number(pageCount) + 1;
    window.localStorage.setItem('pageVisitCount', pageCount);
    return pageCount;
}

/**
 * Changes texts in pop ups on success
 */
function discountPopTextChangesOnSuccess() {
    // button changes
    $('#email-subscribe-btn').addClass('d-none');
    $('#email-subscribe-text').removeClass('d-none');

    // header changes
    $('#discount-popup-header').addClass('d-none');
    $('#discount-popup-confirmation-header').removeClass('d-none');

    // text changes
    $('#discount-popup-text').addClass('d-none');
    $('#discount-popup-confirmation-text').removeClass('d-none');

    if ($('#discount-popup-confirmation-continue-shopping').length > 0) {
        $('#discount-popup-confirmation-continue-shopping').removeClass('d-none');
        $('#email-subscribe-container').addClass('d-none');
    }
}

/**
 * revert pop up texts.
 */
function resetdiscountPopText() {
    // button changes
    $('#email-subscribe-btn').removeClass('d-none');
    $('#email-subscribe-text').addClass('d-none');

    // header changes
    $('#discount-popup-header').removeClass('d-none');
    $('#discount-popup-confirmation-header').addClass('d-none');

    // text changes
    $('#discount-popup-text').removeClass('d-none');
    $('#discount-popup-confirmation-text').addClass('d-none');

    $('#join-loyalty-container').addClass('d-none');
    $('#email-subscribe-container').removeClass('d-none');

    $('#discount-form-email-label').show();
    $('#discount-form-email').removeAttr('disabled');

    if ($('#discount-popup-confirmation-continue-shopping').length > 0) {
        $('#discount-popup-confirmation-continue-shopping').addClass('d-none');
        $('#email-subscribe-container').removeClass('d-none');
    }
}

/**
 * Loyalty pop up elements text handler
 */
function loyaltyPopUpHandler() {
    $('#join-loyalty-container').removeClass('d-none');
    $('#email-subscribe-container').addClass('d-none');
}

/**
 * Hide the popup on close button
 */
function hidePopUp() {
    $('#discount-popup-container, #discount-popup-nb-reward-container').addClass('d-none');
    checkCookie();
    resetdiscountPopText();
}

/**
 * Show the popup on close button
 */
function showPopUp() {
    $('#discount-popup-container, #discount-popup-nb-reward-container').removeClass('d-none');
}

/**
 * Attach event to hide the popup on close button
 */
function attachEventToCloseOpenPopUp() {
    // Case when close button is clicked.
    $(document).on('click', '#discountPopUpCloseBtn, #discount-popup-nb-reward-container .background', function () {
        hidePopUp();
    });
}

/**
 * Attach events to submit email address.
 * If form does not use an input or button type submit and instead uses a div for example, element with ID will fire off the form submission
 * Otherwise form will submit using native HTML functionality
 */
function attachEventForFormSubmission() {
    $(document).on('click', '#email-subscribe-btn', function (e) {
        var buttonSubmit = $(e.target).is(':input[type=submit]') || $(e.target).is(':button[type=submit]');
        if (!buttonSubmit) {
            $('#email-subscribe-form').submit();
        }
    });
}

/**
 * Perform operation when the enters email and subscribe.
  */
function handleEmailInputAndLabel() {
    $('#discount-form-email-label').hide();
    $('#discount-form-email').attr('disabled', 'disabled');
}

/**
 * Perform operation when the enters email and subscribe.
  */
function emailSubscriptionFormSubmission() {
    $('#email-subscribe-form').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        var emailVal = $('#discount-form-email').val();
        if (window.utag_data) {
            $(document).trigger('discount:emailsubscribe');
        }
        var source = $('.email-source').val();
        form.spinner().start();
        var reqObj = {
            email: emailVal,
            source: source
        };
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: reqObj,
            success: function (data) {
                form.spinner().stop();
                if (data.success) {
                    discountPopTextChangesOnSuccess();
                    checkCookie();
                    handleEmailInputAndLabel();
                    if ($('#join-loyalty-container').length) {
                        loyaltyPopUpHandler();
                    }
                }
            },
            error: function (err) {
                form.spinner().stop();
                throw err;
            }
        });
        return false;
    });
}

/**
 * Handle onreadystatechange event.
 * @param {XMLHttpRequest} xhr - XHR instance.
 * @param {HTMLElement} container - Container to append HTML to.
 * @returns {Function} - Onreadystatechange handler.
 */
function createDataHandler(xhr, container) {
    return () => {
        if (xhr.readyState !== 4) {
            return;
        }

        if (xhr.status !== 200) {
            setCookie('hideEmail', 'hide', 1); // No retries on same day.
            return;
        }

        const div = document.createElement('div');
        div.innerHTML = xhr.responseText;

        // Validate before append.
        const popupContainer = div.querySelector('#discount-popup-container, #discount-popup-nb-reward-container');

        if (!popupContainer) {
            setCookie('hideEmail', 'hide', 1); // No retries on same day.
            return;
        }

        // Check if user is on the right page number in session.
        const viewsInSession = checkPageVisitedWithinWebsite();
        let showOnView = popupContainer ? parseInt(popupContainer.getAttribute('data-showonview'), 10) : NaN;
        showOnView = showOnView || 2;

        if (viewsInSession > showOnView) {
            setCookie('hideEmail', 'hide', 365);
            return;
        }

        if (viewsInSession < showOnView) {
            return;
        }

        // Append to page.
        while (div.hasChildNodes()) {
            container.appendChild(div.firstChild);
        }

        // Show and add listeners.
        showPopUp();
        emailSubscriptionFormSubmission();
        attachEventToCloseOpenPopUp();
        attachEventForFormSubmission();
    };
}

/**
 * Initialize.
 */
function initialize() {
    const user = require('../util/user');

    // No action when user is logged in.
    if (user.isLoggedIn()) {
        return;
    }

    // Do not show when discount popup has previously been hidden. Very large drop off expected here.
    if (getCookie('hideEmail') === 'hide') {
        return;
    }

    // Do not show when arriving from e-mail link.
    if (window.location.href.match('utm_source=email')) {
        setCookie('hideEmail', 'hide', 365);
        return;
    }

    // Early exit when URL or container can not be found.
    const input = document.querySelector('.discount-popup-url');
    const url = input ? input.value : null;
    const container = document.querySelector('.page');

    if (!url || !container) {
        return;
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = createDataHandler(xhr, container);
    xhr.open('GET', url, true);
    xhr.send();
}

// Initialize on DOMContentLoaded
$(document).ready(initialize);
