'use strict';

var targetOrigin = window.arTryOnURL;
var targetURL = window.arTryOnPath;
var arTryOnConfig = window.arTryOnConfig;
var isReady = false;
var arModal = $('#ar-tryon-modal');

/**
 * Get the cookie
 * @param {string} arcname - a string representing name of the cookie
 * @return {string} - cookie string
 */
function getCookie(arcname) {
    var name = arcname + '=';
    var ca = document.cookie.split(';');
    var i;
    var c;
    for (i = 0; i < ca.length; i += 1) {
        c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

/**
 *  Set the cookie
 * @param {string} arcname - a string representing name of the cookie
 * @param {string} arcvalue - a string representing the value of the cookie
 * @param {number} exdays - a string representing the expiry
 */
function setCookie(arcname, arcvalue, exdays) {
    var d = new Date();
    var expires;
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = 'expires=' + d.toGMTString();
    document.cookie = arcname + '=' + arcvalue + '; path=/; ' + expires;
}

/**
 *  Checks the cookie
 */
function checkCookie() {
    if (navigator.cookieEnabled) {
        var hideARintroModal = getCookie('hideARintro');
        if (hideARintroModal !== 'hide') {
            setCookie('hideARintro', 'hide', 365);
        }
    }
}

/**
 * Get device type of user agent
 * @return {string} userAgent - Desktop or Mobile useragent type
 */
function getDeviceType() {
    var navUserAgent = navigator.userAgent;
    var userAgent = '';
    // eslint-disable-next-line max-len
    if (navUserAgent.match(/Phone/i) || navUserAgent.match(/DROID/i) || navUserAgent.match(/Android/i) || navUserAgent.match(/webOS/i) || navUserAgent.match(/iPhone/i) || navUserAgent.match(/iPod/i) || navUserAgent.match(/BlackBerry/) || navUserAgent.match(/Windows Phone/i) || navUserAgent.match(/ZuneWP7/i) || navUserAgent.match(/IEMobile/i)) {
        userAgent = 'Mobile';
        // eslint-disable-next-line max-len
    } else if (navUserAgent.match(/Tablet/i) || navUserAgent.match(/iPad/i) || navUserAgent.match(/Kindle/i) || navUserAgent.match(/Playbook/i) || navUserAgent.match(/Nexus/i) || navUserAgent.match(/Xoom/i) || navUserAgent.match(/SM-N900T/i) || navUserAgent.match(/GT-N7100/i) || navUserAgent.match(/SAMSUNG-SGH-I717/i) || navUserAgent.match(/SM-T330NU/i)) {
        userAgent = 'Tablet';
    } else {
        userAgent = 'Desktop';
    }
    return userAgent;
}

const playAR = function () {
    document.getElementById('vyking-sneaker-window').contentWindow.postMessage({
        type: 'VYKING_SNEAKER_WINDOW_PLAY'
    }, targetOrigin);
};

const pauseAR = function () {
    document.getElementById('vyking-sneaker-window').contentWindow.postMessage({
        type: 'VYKING_SNEAKER_WINDOW_PAUSE'
    }, targetOrigin);
};

/**
 * Set the style swatchs as active
 * @param {string} style - style number to set AR experience for.
 */
function setActiveARSwatch(style) {
    var swatch = $('.ar-style-selector[data-arstyle="' + style + '"]');
    swatch.find('span').addClass('active-style');
    swatch.siblings('.ar-style-selector').find('span').removeClass('active-style');
}

/**
 * Set the style on the Vyking Iframe
 * @param {string} style - style number to set AR experience for.
 */
function setStyleAR(style) {
    var availableStyles = window.arTryOnEnabledStyles;
    var styleURI = null;

    $('#ar-tryon-modal').find('.modal-content').spinner().start();

    if (availableStyles[style]) {
        styleURI = availableStyles[style];
        setActiveARSwatch(style);
    } else {
        styleURI = availableStyles[Object.keys(availableStyles)[0]];
        setActiveARSwatch(Object.keys(availableStyles)[0]);
    }
    document.getElementById('vyking-sneaker-window').contentWindow.postMessage({
        type: 'VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORIES',
        accessoryDescriptionUrl: styleURI
    }, targetOrigin);
    playAR();
}

/**
 * Init Vyking iframe
 * @param {Object} config - config object
 */
function initVyking(config) {
    var arTryonIframe = $('#vyking-sneaker-window');
    var tryCount = 0;

    const postConfig = (configparam) => {
        // console.log(`postconfig ${clientWidth}x${clientHeight}`);

        let cameraWidthAR = 360;
        let cameraHeightAR = 640;
        arTryonIframe[0].contentWindow.postMessage({
            type: 'VYKING_SNEAKER_WINDOW_CONFIG',
            config: configparam,
            key: window.arTryOnKey,
            cameraWidth: cameraWidthAR,
            cameraHeight: cameraHeightAR,
            autoPlay: false
        }, targetOrigin);

        arTryonIframe.attr('width', cameraWidthAR);
        arTryonIframe.attr('height', cameraHeightAR);

        tryCount += 1;
    };

    window.onmessage = event => {
        const {
            data
        } = event;

        if (data.type === 'VYKING_SNEAKER_WINDOW_WAITING_FOR_CONFIG') {
            if (tryCount < 20) {
                postConfig(config);
            }
        }

        if (data.type === 'VYKING_SNEAKER_WINDOW_EXPIRY_TIME') {
            // console.info(`Licence expiry date: ${data.expiryTime.toString()}`);
            // If close to licencse expiry reload th configuration file isReady for next time
            if (data.expiryTime.getTime() - new Date().getTime() < 1 * 24 * 60 * 60 * 1000) {
                // console.log(`Expired on ${data.expiryTime.toString()}`);
                fetch(arTryOnConfig, {
                    method: 'GET',
                    cache: 'reload'
                });
            }
        }

        if (data.type === 'VYKING_SNEAKER_WINDOW_READY') {
            isReady = true;
            var selectedStyle = $('.style-attribute.selected').attr('data-attrvalue');
            setStyleAR(selectedStyle);
        }

        if (data.type === 'VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORIES') {
            if (data.complete === 1) {
                $('#ar-tryon-modal').find('.modal-content').spinner().stop();
            }
        }

        if (data.type === 'VYKING_SNEAKER_WINDOW_BUSY_ERROR') {
            $('#ar-tryon-modal').find('.modal-content').spinner().stop();
        }

        if (data.type === 'VYKING_SNEAKER_WINDOW_ERROR') {
            $('#ar-tryon-modal').find('.modal-content').spinner().stop();
            // console.log(`${data.requestType} ${data.value}`);
        }
    };

    arTryonIframe.attr('src', targetURL);
}

/**
 * Load Vyking Config file
 * @param {string} configURI - URL to config file
 */
function loadConfig(configURI) {
    fetch(encodeURI(configURI)).then(function (response) {
        if (!response.ok) {
            throw new Error(`Failed to load configuration from ${configURI}. Status: ${response.status}`);
        }
        response.arrayBuffer().then(function (config) {
            initVyking(config);
        });
    });
}

/**
 * Show Intro screen for AR experience
 */
function showARintro() {
    arModal.find('.artryon-intro').show();
    arModal.find('button.information').hide();
    $('#ar-style-selectors').hide();
    arModal.find('.modal-header').removeClass('artryon-active');
    arModal.find('#vyking-sneaker-window').hide();
}

/**
 * Hide Intro screen for AR experience
 */
function hideARintro() {
    arModal.find('.artryon-intro').hide();
    arModal.find('button.information').show();
    $('#ar-style-selectors').show();
    arModal.find('.modal-header').addClass('artryon-active');
    arModal.find('#vyking-sneaker-window').show();
    $(window).trigger('resize');
}

/**
 * Init the camera view once access is given
 */
function getAccess() {
    hideARintro();
    if (!isReady) {
        $('#ar-tryon-modal').find('.modal-content').spinner().start();
        loadConfig(arTryOnConfig);
    } else {
        var selectedStyle = $('.style-attribute.selected').attr('data-attrvalue');
        setStyleAR(selectedStyle);
    }
}

/**
 * Check Cookie to show AR Intro, if cookie present, Start AR.
 */
function showARIntroFirstTime() {
    if (getCookie('hideARintro') !== 'hide') {
        showARintro();
    } else {
        getAccess();
    }
}

/**
 * Show Mobile or Desktop Version of Modal, if Modal start AR.
 */
function showModalType() {
    var deviceType = getDeviceType();
    if (deviceType === 'Desktop') {
        arModal.find('.artryon-desktop').show();
        arModal.find('.artryon-mobile').hide();
    } else {
        showARIntroFirstTime();
        arModal.find('.artryon-desktop').hide();
    }
}

/**
 * Build markup for style Swatch selectors on AR Modal
 */
function buildARStyleSelectors() {
    var selectors = $('<ul id="ar-style-selectors"></ul>');
    var availableStyles = window.arTryOnEnabledStyles;
    var stylesArray = Object.keys(availableStyles);
    for (var i = 0; i < stylesArray.length; i += 1) {
        var selectorSpan = $('.color-style .style-attribute[data-attrvalue="' + stylesArray[i] + '"] .swatch-value');
        var selector = $('<li></li>');
        selector.addClass('ar-style-selector');
        selector.append('<span></span>');
        selector.find('span').attr('style', selectorSpan.attr('style'));
        selector.attr('data-arstyle', stylesArray[i]);
        selectors.append(selector);
    }
    selectors.attr('data-itemsCount', stylesArray.length);
    selectors.attr('data-slidesToShow', 4.5);

    $('.artryon-mobile').append(selectors);
}

// On page load, build out AR Modal Swatch Selectors
if ('arTryOnEnabledStyles' in window) {
    buildARStyleSelectors();
}

// Event for when AR Modal button is clicked
$('#ar-tryon-modal').on('show.bs.modal', function () {
    showModalType();
    var infoButton = $('button.information');
    $('#ar-tryon-modal .modal-header').append(infoButton);
    $('.ar-style-selector').on('click', function () {
        var selectedStyle = $(this).attr('data-arstyle');
        setStyleAR(selectedStyle);
    });
});

// Event for when AR Modal has opened
$('#ar-tryon-modal').on('shown.bs.modal', function () {
    window.history.pushState('arModal', null, '#arModal');
    $('#ar-style-selectors').not('.slick-initialized').slick({
        slidesToShow: 4.5,
        slidesToScroll: 1,
        infinite: false,
        prevArrow: '',
        nextArrow: ''
    });
});

// Hide modal on back button
$(window).on('popstate', function () {
    $('#ar-tryon-modal').modal('hide');
});

// Click event for Information button on AR modal
$('#ar-tryon-modal button.information').on('click', function (e) {
    e.stopPropagation();
    showARintro();
});

// Click event for Camera Access confirm on AR modal
$('#ar-tryon-modal .artryon-access').on('click', function () {
    checkCookie();
    getAccess();
});

// Event for closing AR Modal
$('#ar-tryon-modal').on('hide.bs.modal', function () {
    if (window.history.state === 'arModal') {
        window.history.replaceState(null, null, '');
        window.history.back();
    }
    pauseAR();
});

$(document).ready(function () {
    // If on page load, AR URL Param is passed, open AR Modal.
    var params = (new URL(window.location)).searchParams;
    var arParam = params.get('AR');
    if (arParam === 'arTryOn') {
        $('#arTryOn-btn').click();
    }
});
