/* eslint-disable no-undef */

'use strict';

// Cookie preference dialog

var cookie = {
    init: function () {
        $(document).on('click', '.manage-cookies a', function (e) {
            e.preventDefault();
            $('html, body').animate(
                { scrollTop: 0 },
                'fast',
                function () {
                    if (window.utag && window.utag.gdpr) {
                        window.utag.gdpr.showConsentPreferences();
                    }
                }
            );
        });
    }
};
cookie.init();
