'use strict';

class AccordionGroupComponent extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('accordion-group-component')) {
            return;
        }

        window.customElements.define('accordion-group-component', AccordionGroupComponent);
    }

    static get observedAttributes() {
        return ['class'];
    }

    constructor() {
        super();

        this.attachShadow({ mode: 'open' });

        const template = document.createElement('template');
        template.innerHTML = `
        <style>
            :host {
                display: flex;
                flex-direction: column;
            }

            :host ::slotted(accordion-component) {
                border-bottom: 1px solid var(--nb-color-gray-ccc);
            }

            :host(.border) {
                border: 1px solid var(--nb-color-gray-ccc);
                border-radius: 4px;
            }
            
            :host(.border) ::slotted(accordion-component:last-of-type){
                border-bottom: none;
            }

            :host(.border-none) ::slotted(accordion-component){
                border-bottom: none;
            }

        </style>

        <slot></slot>
        `;

        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }
}

module.exports = AccordionGroupComponent;
