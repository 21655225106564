'use strict';

const accessibility = require('./util/accessibility');
let slickSliders = [];
let lastActiveElement;

var isButton = function (target, parentClass) {
    var element = target;
    var i = 0;

    while (element) {
        if (parentClass) {
            if (element.classList && element.classList.contains(parentClass)) {
                return element;
            }
        } else if (element.nodeName === 'BUTTON') {
            return element;
        } else if (i === 2) {
            return false;
        }

        i += 1;
        element = element.parentNode;
    }
    return false;
};

var initSlickSliders = async function (sliders) {
    // eslint-disable-next-line no-new, no-undef
    return new Promise((resolve, reject) => {
        const slickSlider = import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js');
        for (let i = 0; i < sliders.length; i += 1) {
            try {
                slickSlider.then(function () {
                    $(sliders[i].className).slick(sliders[i].settings);
                });
                $(sliders[i].className).on('init', function () {
                    if (sliders.length > 1) {
                        // sync sliders
                        let otherSlider = i === 0 ? sliders[1].className : sliders[0].className;
                        $(sliders[i].className).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                            $(otherSlider).slick('slickGoTo', nextSlide);
                        });
                    }
                    resolve();
                });
            } catch (e) {
                reject(e);
            }
        }
    });
};

var toggleDetails = function (trigger) {
    let headerBanner = document.getElementById('header-rotating-banner');
    let pause = document.querySelector('.slick-autoplay-toggle-button');
    let detailsSlider = document.getElementById('Promo_bar_details_slider');
    let overlay = document.getElementById('Promo_banner_details_overlay');

    detailsSlider.classList.toggle('hidden-slider');
    headerBanner.classList.toggle('expanded');
    overlay.classList.toggle('details-open');

    pause.click();

    if (trigger && headerBanner.classList.contains('expanded')) {
        accessibility.trapFocus(detailsSlider, trigger);
        lastActiveElement = trigger;
    } else {
        lastActiveElement.focus();
    }

    accessibility.toggleAriaExpanded(detailsSlider);
};

var initButtons = function () {
    window.addEventListener('click', function (event) {
        let toggleButton = event.target.classList.contains('js-promo-drop-down-button') ? event.target : isButton(event.target, 'js-promo-drop-down-button');

        if (toggleButton) {
            event.preventDefault();
            toggleDetails(toggleButton);
        }
    });
};

var closeModalClickOutside = function (overlay) {
    overlay.addEventListener('click', function () {
        toggleDetails(lastActiveElement);
    });
};

var initDetailSlides = function (slider, isDesktop) {
    let slideDetails = slider.querySelectorAll('.js-rotating-details');
    let sliderWrapper = document.createElement('div');
    let detailsSlider = document.createElement('div');
    let overlay = document.createElement('div');

    overlay.id = 'Promo_banner_details_overlay';
    detailsSlider.classList.add('js-promo-details-container', 'row');
    sliderWrapper.classList.add('promo-details-container', 'hidden-slider');
    sliderWrapper.id = 'Promo_bar_details_slider';
    sliderWrapper.setAttribute('aria-expanded', false);
    sliderWrapper.setAttribute('role', 'dialog');

    slideDetails.forEach(slide => {
        detailsSlider.append(slide);
        if (isDesktop) {
            // remove the bottom close buttons on desktop
            slide.querySelector('.js-closeButton.text-underline').remove();
        }
    });
    document.body.appendChild(overlay);
    sliderWrapper.appendChild(detailsSlider);
    slider.parentElement.appendChild(sliderWrapper);

    closeModalClickOutside(overlay);

    if (!isDesktop) {
        const sliderSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: false,
            arrows: true,
            dots: false,
            adaptiveHeight: true
        };
        slickSliders.push({ className: '.js-promo-details-container', settings: sliderSettings });
    }
};

var initClickOnSlider = function (headerBanner) {
    headerBanner.addEventListener('click', function (event) {
        // eslint-disable-next-line eqeqeq
        if (!headerBanner.classList.contains('expanded') && event.target.tabIndex == -1 && !isButton(event.target)) {
            let currentSlide = headerBanner.querySelector('.slick-active');
            lastActiveElement = currentSlide.querySelector('.js-promo-drop-down-button');
            toggleDetails(lastActiveElement);
        }
    });
};

/**
 * Initialize the Rotating Promo Bar
 */
function init() {
    let headerBanner = document.getElementById('header-rotating-banner');

    if (headerBanner) {
        let slider = headerBanner.querySelector('.col-12');
        let sliderSpeedData = document.querySelector('[data-rotating-promo-banner-speed]');
        let sliderSpeed = 6000;

        if (sliderSpeedData) {
            sliderSpeed = sliderSpeedData.dataset.rotatingPromoBannerSpeed * 1000;
        }

        let sliderSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: sliderSpeed,
            arrows: false,
            dots: true
        };

        slickSliders.push({ className: '.js-rotating-header-banner', settings: sliderSettings });

        if (slider.innerHTML) {
            slider.classList.add('rotating-header-banner', 'js-rotating-header-banner');
            initButtons();
            if (window.innerWidth >= 992) {
                initDetailSlides(slider, true);
            } else {
                initDetailSlides(slider, false);
            }
            initSlickSliders(slickSliders).then(() => {
                document.querySelectorAll('.js-rotating-bar, .js-rotating-details').forEach(slide =>{
                    slide.classList.remove('hidden-slider');
                });
            }).catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error.message);
            });

            initClickOnSlider(headerBanner);
            accessibility.closeDialogsWithEsc();
        }
    } else {
        var emptyDiv = document.querySelector('.js-header-rotating-banner-wrapper');
        // remove empty div from dom
        if (emptyDiv) emptyDiv.remove();
    }
}

module.exports = init;
