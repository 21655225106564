'use strict';

/**
 * Showing the success messages after email subscribe
 * and hide other elements
 */
function showSuccess() {
    $('.special-emarsys-subscribe-form button').hide();
    $('.special-emarsys-subscribe-form .form-label').hide();
    $('.special-emarsys-subscribe-form').find('.subscribed').removeClass('hide');
    $('.special-emarsys-subscribe-form').find('input.subscriber-email').prop('disabled', true);
}

/**
 * Custom javascript for the special email subscription forms on content pages
 * Example is the NZ site's NB Launches page
 *
 * Catch submit on forms with special-emarsys-subscribe-form class
 */
$(document).on('click', '.special-emarsys-subscribe-form button', function (e) {
    e.preventDefault();
    var form = $(this).closest('form');

    var emailReg = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    var emailaddressVal = $(form).find('input.subscriber-email').val();
    var acqSource = $(form).find('input.acq-source').val();

    if (emailaddressVal === '') {
        $('.invalid-email').empty();
        $('.invalid-email').show();
        $('.subscribed').hide();
        $(document).find('.invalid-email').append('<span class="error">Please enter your email address.</span>');
    } else if (!emailReg.test(emailaddressVal)) {
        $('.invalid-email').empty();
        $('.subscribed').hide();
        $(document).find('.invalid-email').show().append('<span class="error">Please enter a valid email address.</span>');
    } else {
        $(document).find('.invalid-email').hide();
        var url = $(this).closest('form.special-emarsys-subscribe-form').attr('action');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                email: emailaddressVal,
                EMAIL_ACQUISITION_SOURCE: acqSource
            },
            success: function (data) {
                if (data.success) {
                    showSuccess();
                } else {
                    $(document).find('.invalid-email').show().append('<span class="error">' + data.msg + '</span>');
                }
            },
            error: function () {
                // empty error handling cause wine
            }
        });
    }
});
