/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */

/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var TRACKING_GLOBAL = 'tracking:global';

/**
 * openModalViewEvent fire view event on content component product click
 * @param {string} pid - gettting parameter
 */

var arTryOnTealium = {
    init: function () {
        $(document).on(TRACKING_GLOBAL, '#arTryOn-btn', function () {
            if (window.utag_data) {
                var artryonbtn = {
                    event_type: 'in_store_ar_try_on',
                    page_name: window.utag_data.page_name,
                    event_message: 'Store Experience',
                    ga_action: 'AR Click',
                    ga_label: window.utag_data.product_id[0]
                };
                tealium.triggerEvent(artryonbtn);
            }
        });

        $(document).on(TRACKING_GLOBAL, '#start-ar-try-on-btn', function () {
            if (window.utag_data) {
                var artryonmodalbtn = {
                    event_type: 'in_store_ar_start',
                    page_name: window.utag_data.page_name,
                    ga_category: 'Store Experience',
                    ga_action: 'AR Start',
                    ga_label: window.utag_data.product_id[0]
                };
                tealium.triggerEvent(artryonmodalbtn);
            }
        });
    }
};

arTryOnTealium.init();
