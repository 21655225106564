'use strict';

require('bootstrap/js/src/util.js');
require('bootstrap/js/src/alert.js');

require('bootstrap/js/src/carousel.js');
require('bootstrap/js/src/collapse.js');

require('bootstrap/js/src/modal.js');

require('bootstrap/js/src/tab.js');
