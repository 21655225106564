'use strict';

const strTabs = '.js-page-tablist';
const strRoles = "[role='tab']";
/**
 * Tab click handler function.
 */
function tabsClickHandler() {
    var thisTabList = $(this).parents(strTabs).find(strRoles);
    thisTabList.each(function () {
        if ($(this).hasClass('active')) {
            $(this).attr('aria-selected', true);
        } else {
            $(this).attr('aria-selected', false);
        }
    });
    var carouselTabInit = require('./carouselTabInitializer');
    setTimeout(carouselTabInit.removeUnavailableProductTiles, 10);
}

/**
 * Trigger the click and focus functionality
 *
 * @param {Event} event - event data
 */
function tabsHandler(event) {
    var tablist = $(this).parents(strTabs).find(strRoles);
    var index = tablist.index($(this));
    var numbTabs = tablist.length;
    var nextId;

    if (numbTabs > 1) {
        if (event.keyCode === 39) { // RIGHT
            nextId = tablist.eq(index + 1);

            // if it is the last not empty tab, then go to first not empty tab
            if (index === numbTabs - 1) {
                nextId = tablist.eq(0);
            }
            nextId.focus(); // focus on next tab
            nextId.click();
        }

        if (event.keyCode === 37) { // LEFT
            nextId = tablist.eq(index - 1);

            // if it is the last not empty tab, then go to first not empty tab
            if (index === 0) {
                nextId = tablist.eq(numbTabs - 1);
            }
            nextId.focus(); // focus on next tab
            nextId.click();
        }
    }
}

module.exports = {
    initTabLayout: function () {
        $(strTabs).each(function () {
            var tablist = $(this).find(strRoles);
            $(tablist).on('keydown', tabsHandler);
            $(tablist).on('click', tabsClickHandler);
        });
    }
};
