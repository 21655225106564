/* eslint-disable */
'use strict';

module.exports = {
    /**
     * Initiate Zendesk widget if enabled in site preferences
     *
     * @param {HTMLElement} zElement - Div holding Zendesk site preference data
     *
     */
    initiateZendeskScript: function (zElement) {
        var zeChatId = zElement.dataset.zeChatId;
        var zeHostUrl = zElement.dataset.zeHostUrl;
        if (typeof window.zE === 'function' && typeof window.zE.hide === 'function' && typeof window.zE.show === 'function') {
            window.zE.show();
        } else if (typeof window.zE !== 'function') {
            // init zopim widget
            if (zeChatId !== 'null') {
                // new lighter zopim widget
                window.zE || (function (e, t, s) {
                    var n = window.zE = window.zEmbed = function () {
                        n._.push(arguments);
                    };
                    var a = n.s = e.createElement(t);
                    var r = e.getElementsByTagName(t)[0];
                    n.set = function (e) {
                        n.set._.push(e);
                    }, n._ = [], n.set._ = [], a.async = true, a.setAttribute('charset', 'utf-8'), a.src = '//static.zdassets.com/ekr/asset_composer.js?key=' + s, n.t = +new Date(), a.type = 'text/javascript', r.parentNode.insertBefore(a, r);
                }(document, 'script', zeChatId));
            } else {
                // legacy zopim widget
                window.zEmbed || (function (e, t) {
                    var n; var o; var d; var i; var s; var a = [];
                    var r = document.createElement('iframe');
                    window.zEmbed = function () {
                        a.push(arguments);
                    }, window.zE = window.zE || window.zEmbed, r.src = 'javascript:false', r.title = '', r.role = 'presentation', (r.frameElement || r).style.cssText = 'display: none', d = document.getElementsByTagName('script'), d = d[d.length - 1], d.parentNode.insertBefore(r, d), i = r.contentWindow, s = i.document;
                    try {
                        o = s;
                    } catch (e) {
                        n = document.domain, r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);', o = s;
                    }
                    o.open()._l = function () {
                        var e = this.createElement('script');
                        n && (this.domain = n), e.id = 'js-iframe-async', e.src = '//assets.zendesk.com/embeddable_framework/main.js', this.t = +new Date(), this.zendeskHost = zeHostUrl, this.zEQueue = a, this.body.appendChild(e);
                    }, o.write('<body onload="document._l();">'), o.close();
                }());
            }
        }
    }
};
