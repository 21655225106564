'use strict';

// var zipCodeJSON = require('../../../config/zipCodes.json');
module.exports = {
    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var index = 0;
        var isMobile = false;
        var userAgent = navigator.userAgent.toLowerCase();

        while (mobileAgentHash[index] && !isMobile) {
            isMobile = userAgent.indexOf(mobileAgentHash[index]) >= 0;
            index += 1;
        }
        return isMobile;
    },
    loadUrl(container) {
        var loadPromises = [];
        var loadElements = container.querySelectorAll('.js-load-url');
        loadElements.forEach(function (/** @type {HTMLElement} */ element) {
            var url = element.dataset.url;
            loadPromises.push(fetch(url, {
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'text/html'
                }
            }).then(function (response) {
                return response.text();
            }).then(function (html) {
                // eslint-disable-next-line no-param-reassign
                element.innerHTML = html;
                Array.from(element.querySelectorAll('script')).forEach(oldScript => {
                    var newScript = document.createElement('script');
                    Array.from(oldScript.attributes)
                        .forEach(attr => newScript.setAttribute(attr.name, attr.value));
                    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
                    oldScript.parentNode.replaceChild(newScript, oldScript);
                });
            }).catch(function () {
                window.location.reload();
            }));
        });
        // eslint-disable-next-line no-undef
        return Promise.all(loadPromises);
    },
    isZipCodeValid: function (postalCode, country, zipCodeFieldSelector, zipCodeJSON) {
        var result;
        var arrayOfCountries = Object.keys(zipCodeJSON);
        var selectedCountry = arrayOfCountries.find(function (countryCode) {
            return countryCode === country;
        });
        var regEx = zipCodeJSON[selectedCountry].regex;
        var regExSelectedCountry = new RegExp(regEx);
        result = regExSelectedCountry.exec(postalCode);

        if (!result) {
            var errorMsg = $('#zipCodeErrorMsg').val();
            $(zipCodeFieldSelector).addClass('is-invalid').siblings('.invalid-feedback').html(errorMsg);
            $('body').trigger('checkout:enableButton', '.next-step-button button');
        }
        if (result) {
            $(zipCodeFieldSelector).removeClass('is-invalid').siblings('.invalid-feedback').empty();
        }

        return result;
    },
    initalizePaypal: function () {
        var loadScript = require('./common/loadScript');
        loadScript('//www.paypalobjects.com/api/checkout.min.js', 'paypal').then(function () {
            import(/* webpackChunkName: "paypal" */ './paypalUtils.js').then(function () {
                import(/* webpackChunkName: "paypal" */ './paypalMFRA.js').then(function () {
                    if (window.paypalUtils) {
                        window.paypalUtils.initalizePaypal();
                    }
                });
            });
        });
    },
    capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    /**
     * appends params to a url
     * @param {string} url - Original url
     * @param {Object} params - Parameters to append
     * @returns {string} result url with appended parameters
     */
    appendToUrl: function (url, params) {
        var newUrl = url;
        newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
            return key + '=' + encodeURIComponent(params[key]);
        }).join('&');

        return newUrl;
    },

    checkForSessionLogout: function (data) {
        if (data) {
            if (data.forcedLogout) {
                $('#concurrent-sessions-modal').modal('show').on('hidden.bs.modal', function () {
                    $.spinner().start();
                    window.location.reload();
                });
            }
        } else if ($('[data-forcedlogout="true"]').length) {
            $('#concurrent-sessions-modal').modal('show');
        }
    },

    /**
     * Formats the given amount to display with or without tax included text.
     * @param {number} amount - The amount to be formatted.
     * @returns {string} The formatted amount, with or without tax included text.
     */
    getAmountToDisplay: function (amount) {
        if (!window.sitePrefs.showTaxIncludedText) {
            return amount;
        }

        var taxIncludedText = window.sitePrefs.resources.taxIncludedText;
        if (amount.includes(taxIncludedText)) {
            return amount;
        }

        return `${amount} ${window.sitePrefs.resources.taxIncludedText}`;
    },
    TAB_KEY_CODE: 9,
    ENTER_KEY_CODE: 13,
    ESC_KEY_CODE: 27,
    UP_ARROW_KEY_CODE: 38,
    DOWN_ARROW_KEY_CODE: 40
};
