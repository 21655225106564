/* global $ */

'use strict';

var tealium = require('./tealium');

/**
 * Common analytics data used in loqate events
 * @returns {Object} having common properties used across loqate modal events
 */
function commonAnalyticsData() {
    var commonProps = {};
    if (window.utag_data) {
        var loadData = window.utag_load_data;
        commonProps = {
            page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
            page_name: window.utag_data.page_name,
            login_type: window.utag_data.login_type,
            ga_category: 'address validation'
        };
    }
    return commonProps;
}

/**
 * Analytics to monitor when the Loqate "Enter Address Manually" link is clicked
 */
var clickEnterAddressManually = function () {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventObj = {
            event_type: 'address_validation_enter_address_manually',
            event_message: 'address_validation_enter_address_manually',
            ga_action: 'click',
            ga_label: 'enter address manually',
            event_detail: 'enter_address_manually'
        };
        var tealiumObject = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(tealiumObject);
    }
};

/**
 * Analytics to monitor when the Loqate address verification modal is opened
 */
var openAddressVerificationModal = function () {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventObj = {
            event_type: 'address_validation_modal_open',
            event_message: 'address_validation_modal_open',
            ga_action: 'open',
            ga_label: 'confirm shipping address modal',
            event_detail: 'confirm_shipping_address_modal_open'
        };
        var tealiumObject = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(tealiumObject);
    }
};

/**
 * Analytics to monitor when the Loqate address verification modal is closed
 */
var closeAddressVerificationModal = function () {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventObj = {
            event_type: 'address_validation_modal_close',
            event_message: 'address_validation_modal_close',
            ga_action: 'close',
            ga_label: 'confirm shipping address modal',
            event_detail: 'confirm_shipping_address_modal_close'
        };
        var tealiumObject = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(tealiumObject);
    }
};

/**
 * Analytics to monitor when the user selects the suggested address in the Loqate verification modal
 */
var suggestedAddressVerification = function () {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventObj = {
            event_type: 'address_validation_select_suggested_address',
            event_message: 'address_validation_select_suggested_address',
            ga_action: 'select',
            ga_label: 'suggested address',
            event_detail: 'address_validation_select_suggested_address'
        };
        var tealiumObject = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(tealiumObject);
    }
};

/**
 * Analytics to monitor when the user selects the entered address in the Loqate verification modal
 */
var enteredAddressVerification = function () {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventObj = {
            event_type: 'address_validation_select_entered_address',
            event_message: 'address_validation_select_entered_address',
            ga_action: 'select',
            ga_label: 'entered address',
            event_detail: 'address_validation_select_entered_address'
        };
        var tealiumObject = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(tealiumObject);
    }
};

/**
 * Analytics to monitor when the user saves the selected address in the Loqate verification modal
 * @param {string} selected - The advanceToNextStage
 */
var saveAddressVerification = function (selected) {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventDetail = selected === 'suggested' ? 'select_suggested_address' : 'select_entered_address';
        var eventObj = {
            event_type: 'address_validation_save_and_continue',
            event_message: 'address_validation_save_and_continue',
            ga_action: 'click',
            ga_label: 'submit save and continue',
            event_detail: eventDetail
        };
        var tealiumObject = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(tealiumObject);
    }
};

var loqate = {
    init: function () {
        $(document).on('loqate:enter-manual-address', clickEnterAddressManually);
        $(document).on('loqate:address-verification-modal-open', openAddressVerificationModal);
        $(document).on('loqate:address-verification-modal-close', closeAddressVerificationModal);
        $(document).on('loqate:address-verification-modal-suggested', suggestedAddressVerification);
        $(document).on('loqate:address-verification-modal-entered', enteredAddressVerification);
        $(document).on('loqate:address-verification-modal-save', saveAddressVerification);
    }
};

exports.init = loqate.init;
