/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint camelcase: [0, {properties: never}] */

'use strict';

var tealium = require('./../pages/tealium');

/**
 * Common analytics data used in chat events
 * @returns {Object} having common properties used across chat modal events
 */
function commonAnalyticsData() {
    if (window.utag_data) {
        var loadData = window.utag_load_data;
        return {
            page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
            page_name: window.utag_data.page_name,
            login_type: window.utag_data.login_type,
            pdp_state: window.utag_data.pdp_state,
            ga_category: 'chat quick help',
            ga_action: 'click'
        };
    }
}

/**
 * Analytics to monitor chat modal is launched
 */
var openChatModalTracker = function () {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventObj = {
            event_detail: 'open_modal',
            event_type: 'chat_quick_help_open_modal',
            event_message: 'chat_quick_help_open_modal',
            ga_label: 'open modal'
        };
        var chatObj = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(chatObj);
    }
};

/**
 * Analytics to monitor chat modal is closed
 */
function closeChatModalTracker() {
    $('#emeaHelpModal').on('hide.bs.modal', function () {
        if (window.utag_data) {
            var commonProps = commonAnalyticsData();
            var eventObj = {
                event_detail: 'close_modal',
                event_type: 'chat_quick_help_close_modal',
                event_message: 'chat_chat_close_modal',
                ga_label: 'close modal'
            };
            var chatObj = Object.assign(commonProps, eventObj);
            tealium.triggerEvent(chatObj);
        }
    });
}

/**
 * Analytics to monitor click on link Track your Order from Chat Modal
 */
function clickTrackYourOrderTracker() {
    $(document).on('tealium:ChatOrderStatusLink', function (_e, callBackFn) {
        if (window.utag_data) {
            var commonProps = commonAnalyticsData();
            var eventObj = {
                event_detail: 'track_your_order',
                event_type: 'chat_quick_help_track_your_order',
                event_message: 'chat_quick_help_track_your_order',
                ga_label: 'track your order'
            };
            var chatObj = Object.assign(commonProps, eventObj);
            tealium.triggerEvent(chatObj, '', callBackFn);
        }
    });
}

/**
 * Analytics to monitor click on link Start A Return from Chat Modal
 */
function clickStartReturnTracker() {
    $(document).on('tealium:ChatOrderReturnLink', function (_e, callBackFn) {
        if (window.utag_data) {
            var commonProps = commonAnalyticsData();
            var eventObj = {
                event_detail: 'start_a_return',
                event_type: 'chat_quick_help_start_a_return',
                event_message: 'chat_quick_help_start_a_return',
                ga_label: 'start a return'
            };
            var chatObj = Object.assign(commonProps, eventObj);
            tealium.triggerEvent(chatObj, '', callBackFn);
        }
    });
}

/**
 * Analytics to monitor click on link FAQ from Chat Modal
 */
function clickFAQSTracker() {
    $(document).on('tealium:ChatFAQLink', function (_, callBackFn) {
        if (window.utag_data) {
            var commonProps = commonAnalyticsData();
            var eventObj = {
                event_detail: 'faqs',
                event_type: 'chat_quick_help_faqs',
                event_message: 'chat_quick_help_faqs',
                ga_label: 'faqs'
            };
            var chatObj = Object.assign(commonProps, eventObj);
            tealium.triggerEvent(chatObj, '', callBackFn);
        }
    });
}

/**
 * Analytics to monitor start a chat event from Chat Modal
 */
var startChatTracker = function () {
    if (window.utag_data) {
        var commonProps = commonAnalyticsData();
        var eventObj = {
            event_detail: 'chat_with_us',
            event_type: 'chat_quick_help_chat_with_us',
            event_message: 'chat_quick_help_chat_with_us',
            ga_label: 'chat with us'
        };
        var chatObj = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(chatObj);
    }
};

module.exports = {
    init: function () {
        $(document).on('chat:open_modal', openChatModalTracker);
        $(document).on('chat:start_chat', startChatTracker);
    },
    closeChatModalTracker: closeChatModalTracker,
    clickTrackYourOrderTracker: clickTrackYourOrderTracker,
    clickStartReturnTracker: clickStartReturnTracker,
    clickFAQSTracker: clickFAQSTracker
};
