/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint camelcase: [0, {properties: "never"}] */
/* eslint-disable no-plusplus */

'use strict';

var tealium = require('./tealium');
var TRACKING_FOOTER = 'tracking:footer';
var TRACKING_GLOBAL = 'tracking:global';

var footer = {
    init: function () {
        // Footer  link click events
        $('.footer-item a').click(function (e) {
            if (window.utag_data) {
                var data = window.utag_data;
                var obj = tealium.checkElement(e);
                var groupTitleEle = $(e.target).closest('.footer-item').find('div.title')[0];
                var groupTitle = (groupTitleEle && groupTitleEle.textContent) ? groupTitleEle.textContent.trim() : '';

                var footerObject = {
                    event_type: 'footer_nav_click',
                    page_name: data.page_name,
                    nav_click: 'footer:' + obj.label,
                    event_message: 'footer:' + obj.label,
                    ga_action: 'footer',
                    ga_label: obj.label,
                    site: data.site,
                    site_section: data.site_section,
                    page_group: data.page_group,
                    page_context_type: data.page_context_type,
                    page_context_title: data.page_context_title,
                    login_status: data.login_status,
                    site_type: data.site_type,
                    element_type: 'a',
                    element_text: obj.label,
                    user_id: data.user_id,
                    ga4_event: 'navigation_click',
                    nav_type: 'footer nav',
                    nav_level1: groupTitle,
                    nav_level2: obj.label,
                    nav_level3: '',
                    page_location: window.location.pathname

                };
                tealium.triggerEvent(footerObject);
            }
        });

        // Footer  subscribe link click events
        $('#footerSubscribe').click(function (e) {
            if (window.utag_data) {
                var subscribeObject = {
                    event_type: 'footer_nav_email_sign_up',
                    page_name: window.utag_data.page_name,
                    nav_click: 'footer:EMAIL SIGN-UP',
                    event_message: 'footer:EMAIL SIGN-UP',
                    ga_action: 'Submit',
                    ga_label: 'Footer'

                };
                tealium.triggerEvent(subscribeObject);

                var data = window.utag_data;
                var subscribeViewObject = {
                    heirarchy: '',
                    ip_address: data.ip_address,
                    page_name: 'newsletter',
                    page_context_type: 'newsletter',
                    page_context_title: 'newsletter',
                    site_section: data.site_section,
                    page_section: '',
                    site_sub_section: '',
                    site_sub_section_level3: '',
                    site_sub_section_level4: '',
                    site_sub_section_level5: '',
                    error_text: '',
                    site_type: data.site_type,
                    country: data.country,
                    language: data.language,
                    locale: data.locale,
                    login_type: data.login_type,
                    geoPostal: data.geoPostal,
                    promo_id: data.promo_id,
                    promo_name: data.promo_name,
                    promo_creative: data.promo_creative,
                    promo_position: data.promo_position,
                    order_currency: data.order_currency,
                    user_flow: data.user_flow,
                    viewport: data.viewport,
                    customer_email: data.customer_email,
                    hashed_email: data.hashed_email,
                    user_anonymous: data.user_anonymous,
                    user_authenticated: data.user_authenticated,
                    user_registered: data.user_registered,
                    customer_status: data.customer_status,
                    customer_id: data.customer_id,
                    customer_type: data.customer_type,
                    customer_city: data.customer_city,
                    customer_state: data.customer_state,
                    order_subtotal: data.order_subtotal,
                    cart_order_subtotal: data.cart_order_subtotal,
                    cart_product_name: data.cart_product_name,
                    cart_product_brand: data.cart_product_brand,
                    cart_product_unit_price: data.cart_product_unit_price,
                    cart_product_sku: data.cart_product_sku,
                    cart_product_style_number: data.cart_product_style_number,
                    cart_product_id: data.cart_product_master,
                    adobe_cart_product_id: data.adobe_product_id,
                    cart_product_variant_id: data.cart_product_variant_id,
                    cart_product_master: data.cart_product_master,
                    cart_product_category: data.cart_product_category,
                    cart_product_quantity: data.cart_product_quantity,
                    cart_product_vat: data.cart_product_vat,
                    cart_product_coupon_discount: data.cart_product_coupon_discount,
                    cart_product_coupon_promo: data.cart_product_coupon_promo,
                    cart_product_gender: data.cart_product_gender,
                    cart_product_color: data.cart_product_color,
                    cart_product_size: data.cart_product_size,
                    cart_product_width: data.cart_product_width,
                    cart_product_gbu: data.cart_product_gbu,
                    cart_product_line: data.cart_product_line,
                    cart_product_silhouette: data.cart_product_silhouette,
                    cart_product_type: data.cart_product_type,
                    event_type: 'newsletter',
                    ga_action: 'Newsletter | ' + data.page_name,
                    ga_label: 'Newsletter'
                };
                tealium.triggerEvent(subscribeViewObject, 'view');
            }
        });

        $(document).on(TRACKING_GLOBAL, '.subscribe-form .subscribe-btn', function () {
            var customerEmail = $(document).find('input.subscriber-email').val();
            var elemText = $(this).text().trim();
            var attrName = $(this)[0].nodeName;
            if (window.utag_data && window.utag_load_data) {
                var loadData = window.utag_load_data;
                var notifySubscribeObjFooter = {
                    customer_email: customerEmail,
                    event_message: 'Sign up Subscribe',
                    page_name: loadData.page_name,
                    page_group: window.utag_load_data && window.utag_load_data.page_group ? window.utag_load_data.page_group : '',
                    ga_action: 'Sign up | Subscribe',
                    ga_label: '',
                    element_type: attrName,
                    element_text: elemText,
                    page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                    event_type: 'email_signup',
                    event_detail: 'footer',
                    page_location: window.location.pathname
                };
                tealium.triggerEvent(notifySubscribeObjFooter);
            }
        });

        $(document).on('discount:emailsubscribe', function () {
            if (!window.utag_data) {
                return;
            }

            var discountObj = {
                event_type: 'email_subscribe',
                page_name: window.utag_data.page_name,
                ga_action: 'Join',
                ga_label: window.utag_data.page_name
            };
            tealium.triggerEvent(discountObj);
        });

        $(document).on(TRACKING_GLOBAL, '#discountPopUpJoinBtn', function () {
            if (!window.utag_data) {
                return;
            }

            var discountObj = {
                event_type: 'email_join',
                page_name: window.utag_data.page_name,
                ga_action: 'Submit',
                ga_label: window.utag_data.page_name
            };
            tealium.triggerEvent(discountObj);
        });

        $(document).on(TRACKING_FOOTER, '#continue-country', function () {
            var site = $(this).attr('data-code');
            if (window.utag_data) {
                var changeCountryObj = {
                    event_type: 'continue_country',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Continue to Site | ' + site,
                    ga_label: ''
                };
                tealium.triggerEvent(changeCountryObj);
            }
        });

        $(document).on(TRACKING_GLOBAL, '#change-country-container .see-all-region', function () {
            if (window.utag_data) {
                var regionObj = {
                    event_type: 'see_all_regions',
                    page_name: window.utag_data.page_name,
                    ga_action: 'See All Regions',
                    ga_label: ''
                };
                tealium.triggerEvent(regionObj);
            }
        });

        $(document).on('country:suggested', function (event, code) {
            if (window.utag_data) {
                var changeCountryObj = {
                    event_type: 'go_to_recommended_site',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Go to recommended site |' + code,
                    ga_label: ''
                };
                tealium.triggerEvent(changeCountryObj);
            }
        });

        $(function () {
            var url = $('.tealiumbadges-api').data('tealiumbadgesurl');
            if (url) {
                $(window).on('load', function (e) {
                    var tealiumVa = JSON.parse(window.localStorage.getItem('tealium_va'));
                    if (url && tealiumVa && 'badges' in tealiumVa) {
                        var localBadges = tealiumVa.badges;
                        var keys = Object.keys(localBadges);
                        var badges = '';
                        for (var i = 0; i < keys.length; i++) {
                            var badge = keys[i].split('.').pop();
                            badges = badges + badge + '|';
                        }
                        if (badges !== '') {
                            $.ajax({
                                url: url,
                                type: 'get',
                                dataType: 'json',
                                data: badges,
                                async: true,
                                success: function (data) {},
                                error: function () {}
                            });
                        }
                    }
                });
            }
        });
    }
};

footer.init();
