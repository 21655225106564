/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint camelcase: [0, {properties: never}] */

'use strict';

var tealium = require('./../pages/tealium');

/**
 * Common analytics data used in chat events
 * @returns {Object} having common properties used across chat modal events
 */
function commonAnalyticsData() {
    if (window.utag_data) {
        var commonProps = {
            site: window.utag_data.site,
            site_section: window.utag_data.site_section,
            page_name: window.utag_data.page_name,
            page_group: window.utag_data.page_type,
            page_context_type: window.utag_data.page_context_type,
            page_context_title: window.utag_data.page_context_title,
            login_status: window.utag_data.login_status,
            site_type: window.utag_data.site_type,
            user_id: window.utag_data.user_id
        };
        return commonProps;
    }
}

/**
 * Common analytics data used in chat events
 */
function productTileCLick() {
    $(document).on('click', '.recommender-carousel-cont a.product', function () {
        var $productLink = $(this);
        var $carouselCont = $productLink.parents('.recommender-carousel-cont');
        var recommenderName = $carouselCont.data('recommender-name');
        var productName = $productLink.attr('title') || '';
        var commonProps = commonAnalyticsData();
        var eventObj = {
            element_type: $productLink[0].nodeName,
            element_text: productName,
            event_type: 'recommended_product',
            item_click: productName,
            page_location: window.location.pathname,
            event_detail: recommenderName
        };
        var pdpEventData = Object.assign(commonProps, eventObj);
        tealium.triggerEvent(pdpEventData);
    });
}

module.exports = {
    productTileCLick: productTileCLick
};
