/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */

/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var TRACKING_GLOBAL = 'tracking:global';

var triggerEvent = function (action, message, label) {
    if (!window.utag_data) {
        return;
    }
    var addnewAddressObj = {
        event_type: 'my_account_change',
        change_action: action,
        page_name: window.utag_data.page_name,
        page_location: window.location.pathname,
        page_type: window.utag_data.page_type !== undefined ? window.utag_data.page_type : '',
        page_group: window.utag_data.page_group !== undefined ? window.utag_data.page_group : '',
        event_message: message,
        ga_action: 'Address Book',
        ga_label: label
    };
    tealium.triggerEvent(addnewAddressObj);
};

var address = {
    init: function () {
        $(document).on(TRACKING_GLOBAL, '.nb-button.add-new-address', function () { // Old UI this flow doesn't exist on new UI
            triggerEvent('start_add_address', 'Address Book | Add Address', 'Start-Add Address');
        });

        $('.show-address-form-js').on('address:openAddressForm', function () { // Old UI
            triggerEvent('start_add_address', 'Address Book | Add Address', 'Add Address');
        });

        $(document).on('address:openAddressForm', function () { // New UI this same button as above opens the address form
            triggerEvent('start_add_address', 'Address Book | Add Address', 'Add Address');
        });

        $(document).on('address:submit', function () {
            triggerEvent('save_address', 'Address Book | Save  Address', 'Save Address');
        });

        $(document).on('address:edit', function () {
            triggerEvent('update_address', 'Address Book | Update  Address', 'Update Address');
        });

        $(document).on('address:remove', function () {
            triggerEvent('remove_address', 'Address Book | Remove  Address', 'Remove Address');
        });
    }
};

address.init();
