/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var utils = require('./utils');
var TRACKING_MINICART = 'tracking:minicart';
var TRACKING_GLOBAL = 'tracking:global';

/**
 * Returns common data needed for the checkout buttons
 * @param {Object} windowUtagData - window.utag_data client side global object
 * @returns {Object} - object with tealiumData
 */
function getCommonUtagData(windowUtagData) {
    var utagData = windowUtagData;
    var keys = ['site', 'site_section', 'page_name', 'page_context_type', 'page_context_title', 'login_status',
        'site_type', 'user_id', 'product_brand', 'product_category', 'product_color', 'product_gender', 'product_id', 'product_impression_id',
        'product_line', 'product_type', 'product_list', 'product_master', 'product_name', 'product_quantity', 'product_size', 'product_sku', 'product_silhouette',
        'product_style_number', 'product_subcategory', 'item_category', 'item_category2', 'item_category3', 'item_category4', 'item_category5',
        'original_product_unit_price', 'item_id_price', 'category_path', 'item_list_id'];
    var tealiumData = utils.populateFromKeys(keys, window.utag_data, {});
    tealiumData.page_group = window.utag_load_data && window.utag_load_data.page_type ? window.utag_load_data.page_type : '';
    tealiumData.item_id = utagData.cart_product_master;
    tealiumData.item_brand = utagData.cart_product_brand;
    tealiumData.item_name = utagData.cart_product_name;
    tealiumData.item_variant = utagData.cart_product_variant_id;
    tealiumData.price = utagData.cart_product_unit_price;
    tealiumData.product_list = utagData.cart_product_category_path;
    tealiumData.quantity = utagData.cart_product_quantity;
    tealiumData.item_list_name = utagData.item_list_name;
    return tealiumData;
}

/**
 * Creates and fires a beginCheckout event
 * @param {string} checkoutType - method of checkout
 * @param {string} elementType - element type
 * @param {string} elementText - element name
 */
function beginCheckout(checkoutType, elementType, elementText) {
    var baseData = getCommonUtagData(window.utag_data);

    var buttonData = {
        event_type: 'begin_checkout',
        event_name: 'begin_checkout',
        checkout_type: checkoutType,
        checkout_step: 'cart',
        element_type: elementType,
        element_text: elementText
    };

    var ga4checkoutObj = Object.assign(baseData, buttonData);

    tealium.triggerEvent(ga4checkoutObj);
    $.cookie('CheckoutType', ga4checkoutObj.checkout_type, { path: '/' });
}

var cart = {
    init: function () {
        $('.cart-page .cart-checkout-buttons .checkout-continue .checkout-btn').on('click', function () {
            if (window.utag_data) {
                var checkoutObj = {
                    event_type: 'My Cart',
                    page_name: window.utag_data.page_name,
                    event_message: 'My Cart | checkout',
                    ga_action: 'Go To Checkout | Mini Cart ',
                    ga_label: ''
                };
                tealium.triggerEvent(checkoutObj);
                // New GA4 implementation
                beginCheckout('standard_cart_checkout', $(this)[0].nodeName, $(this)[0].text.trim());
            }
        });
        $('.cart-page .cart-checkout-buttons .applepay').on('click', function () {
            if (window.utag_data) {
                // New GA4 implementation
                beginCheckout('standard_cart_applepay_checkout', $(this)[0].nodeName, $(this)[0].text.trim());
            }
        });
        $('.promo-content .promo-code-btn').on('click', function () {
            var promoCode = $('.promo-code-form #couponCode').val();
            if (window.utag_data) {
                var applyPromoObj = {
                    event_type: 'promo_reward',
                    order_coupon_code: promoCode,
                    order_coupon_discount: promoCode,
                    customer_city: window.utag_data.customer_city,
                    customer_state: window.utag_data.customer_state,
                    page_name: window.utag_data.page_name,
                    event_message: 'promo applied | ' + promoCode,
                    ga_action: 'Reward Redemption',
                    ga_label: promoCode

                };
                tealium.triggerEvent(applyPromoObj);
            }
        });
        $(document).on('cart:update-quantity', function (event, eventName, gaName, productId) {
            if (window.utag_data) {
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var productObj = {
                        event_type: eventName,
                        page_name: window.utag_data.page_name,
                        adobe_user_flow: 'cart change',
                        adobe_product_id: data.adobe_product_id,
                        product_name: data.product_name,
                        product_brand: data.product_brand,
                        product_unit_price: data.product_unit_price,
                        product_sku: data.product_sku,
                        product_style_number: data.product_style_number,
                        product_id: data.product_master,
                        product_variant_id: data.product_variant_id,
                        product_master: data.product_master,
                        product_category: data.product_category,
                        product_subcategory: data.product_subcategory,
                        product_quantity: data.product_quantity,
                        product_vat: data.product_vat,
                        product_gender: data.product_gender,
                        product_color: data.product_color,
                        product_size: data.product_size,
                        product_width: data.product_width,
                        product_gbu: data.product_gbu,
                        product_line: data.product_line,
                        product_type: data.product_type,
                        product_silhouette: data.product_silhouette,
                        ga_action: gaName,
                        ga_label: productId,
                        category_path: data.category_path,
                        original_product_unit_price: data.original_product_unit_price
                    };
                    tealium.triggerEvent(productObj);
                });
            }
        });

        $(document).on('cart:loyaltypromo', function (event, data) {
            var promoCode = data;
            if (window.utag_data) {
                var PromoObj = {
                    event_type: 'promo_reward',
                    order_coupon_code: promoCode,
                    order_coupon_discount: promoCode,
                    customer_city: window.utag_data.customer_city,
                    customer_state: window.utag_data.customer_state,
                    page_name: window.utag_data.page_name,
                    event_message: 'promo applied | ' + promoCode,
                    ga_action: 'Reward Redemption',
                    ga_label: promoCode
                };
                tealium.triggerEvent(PromoObj);
            }
        });

        $('.cart-delete-confirmation-btn').on('click', function () {
            var productId = $(this).data('pid');
            var event_detail = $(this).data('initiator');
            var elemText = $(this).text().trim();
            var elemType = $(this)[0].nodeName;
            if (window.utag_data) {
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var baseData = utils.getCommonUtagProductData(data);
                    var siteData = utils.getCommonUtagSiteData(window.utag_data);

                    var buttonData = {
                        event_type: 'cart_remove_mini_cart',
                        event_detail: event_detail,
                        adobe_user_flow: 'cart change',
                        ga_action: 'Confirm Remove Product',
                        ga_label: productId,
                        site_section: 'modal side panel',
                        element_type: elemType,
                        element_text: elemText,
                        category_path: data.category_path,
                        original_product_unit_price: data.original_product_unit_price
                    };

                    var removeObj = Object.assign(baseData, siteData, buttonData);

                    tealium.triggerEvent(removeObj);
                });
            }
        });

        $(document).on(TRACKING_MINICART, '.minicart-items-container .product-summary .remove-product', function () {
            var productId = $(this).attr('data-pid');
            if (window.utag_data) {
                var removeObj = {
                    event_type: 'cart_start_remove',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Remove Product | Mini Cart',
                    ga_label: productId
                };
                tealium.triggerEvent(removeObj);
            }
        });

        $(document).on(TRACKING_MINICART, '.minicart-footer .view-cart', function () {
            if (window.utag_data) {
                var viewCartObj = {
                    event_type: 'mini_cart_view_cart',
                    page_name: window.utag_data.page_name,
                    ga_action: 'View Full Cart | Mini Cart',
                    ga_label: ''
                };
                tealium.triggerEvent(viewCartObj);
            }
        });

        $(document).on(TRACKING_MINICART, '.minicart-footer .checkout-btn', function () {
            if (window.utag_data) {
                var viewCartObj = {
                    event_type: 'My Bag',
                    page_name: window.utag_data.page_name,
                    event_message: 'My Cart| checkout',
                    ga_action: 'Go To Checkout | Mini Cart',
                    ga_label: ''
                };
                tealium.triggerEvent(viewCartObj);
                // New GA4 implementation
                beginCheckout('mini_cart_checkout', $(this)[0].nodeName, $(this)[0].text.trim());
            }
        });
        $(document).on(TRACKING_GLOBAL, '.cart-add-to-wishlist', function () {
            var pid = $('.cart-product-id').text();
            var productInfo = tealium.getProductDetails(pid);
            var elemText = $(this).find('span').text().trim();
            var elemType = $(this)[0].nodeName;

            productInfo.then(function (data) {
                $(document).trigger('wishlist:add-product', {
                    product_data: data,
                    event_type: 'move_to_wishlist',
                    ga_action: 'Move to Wishlist | Mini Cart',
                    ga_label: pid,
                    element_text: elemText,
                    element_type: elemType
                });
            });
        });

        $(document).on('paypaltrigger', function () {
            if (window.utag_data) {
                var paypalObj = {
                    event_type: 'pay with wallet',
                    selected_payment_method: 'PayPal',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Payment Method | Mini Cart',
                    ga_label: 'PayPal'
                };
                tealium.triggerEvent(paypalObj);
                // New GA4 implementation
                var checkoutType = 'mini_cart_paypal_checkout';
                if ($('.container.cart-page').length) {
                    checkoutType = 'standard_cart_paypal_checkout';
                }
                beginCheckout(checkoutType, 'DIV', 'Paypal Checkout');
            }
        });

        $(document).on(TRACKING_MINICART, '.minicart-footer .applepay', function () {
            if (window.utag_data) {
                var appPay = {
                    event_type: 'apple_pay',
                    selected_payment_method: 'Apple Pay',
                    page_name: window.utag_data.page_name,
                    event_message: 'Apple Pay| ' + tealium.getDevice(),
                    ga_action: 'Payment Method | Mini Cart',
                    ga_label: 'Apple Pay'
                };
                tealium.triggerEvent(appPay);
                // New GA4 implementation
                beginCheckout('mini_cart_applepay_checkout', $(this)[0].nodeName, $(this)[0].text.trim());
            }
        });

        $(document).on('paypal:error-tealium', function () {
            if (window.utag_data) {
                var paypalErrObj = {
                    event_type: 'pay with wallet',
                    selected_payment_method: 'PayPal',
                    page_name: window.utag_data.page_name,
                    ga_action: 'PayPal Error',
                    ga_label: 'PayPal',
                    ga_category: 'Ecommerce'
                };
                tealium.triggerEvent(paypalErrObj);
            }
        });
    }
};

exports.init = cart.init;
