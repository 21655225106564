'use strict';

let needHelpButton;
let attachedEventListiner = false;
let $chatButton;
const $chatModal = $('#emeaHelpModal');
const chatText = '.js-chat-text';
const helpEnabledButton = '.helpButtonEnabled';
/**
 * Show embedded help button.
 * @param {Object} event - Click event.
 */
function showEmbeddedHelpButton(event) {
    if (event) {
        event.preventDefault();
    }
    const embeddedServiceHelpButton = $('.embeddedServiceHelpButton');
    const helpButtonEnabled = $(helpEnabledButton);

    // Show help button.
    embeddedServiceHelpButton.show();

    // Enabled help button is not available when "Agent Offline".
    if (helpButtonEnabled.length === 0) {
        setTimeout(() => {
            $(helpEnabledButton).click();
        }, 1000);
    } else {
        helpButtonEnabled.click();
    }
}

/**
 * Load chat scripts and show help button once done.
 * @param {Object} event - Click event.
 */
function loadChatScripts(event) {
    event.preventDefault();

    // Load the scripts needed.
    if (!window.eswLoaded) {
        window.loadESW();
    }

    // Make sure not to load twice.
    needHelpButton.off('click', loadChatScripts);

    // Poll for the presence of the result of a loaded script, the help button.
    const checkExist = setInterval(function () {
        const embeddedHelpButton = $('.embeddedServiceHelpButton');
        if (!embeddedHelpButton.length) {
            return;
        }

        clearInterval(checkExist);

        needHelpButton.on('click', showEmbeddedHelpButton);
        showEmbeddedHelpButton(null);
    }, 300);
}

/**
 * Handles the event after the chat window is minimized.
 */
function handleAfterMinimize() {
    $chatButton.removeClass('d-none');
    $('.minimizedContainer').hide();
}
/**
 * Handles the event after the chat window is maximized.
 */
function handleAfterMaximize() {
    $chatButton.addClass('d-none');
    $chatModal.modal('hide');
}

/**
 * Handles the event after the chat window is destroyed.
 */
function handleAfterDestroy() {
    $chatButton.removeClass('d-none');
}
/**
 * Handles the event when the help button is clicked.
 */
function handleOnHelpButtonClick() {
    $chatButton.addClass('d-none');
}
/**
 * The call happens on page load and retrieves settings for the chat button requested.
 */
function handleOnSettingsCallCompleted() {
    if (!window.embedded_svc.componentInitInProgress) {
        $chatButton.removeClass('d-none');
    }
}
/**
 * Adds event handlers for chat button events.
 */
function addEventHandlers() {
    if (!attachedEventListiner) {
        window.embedded_svc.addEventHandler('afterMinimize', handleAfterMinimize);
        window.embedded_svc.addEventHandler('afterMaximize', handleAfterMaximize);
        window.embedded_svc.addEventHandler('afterDestroy', handleAfterDestroy);
        window.embedded_svc.addEventHandler('onHelpButtonClick', handleOnHelpButtonClick);
        window.embedded_svc.addEventHandler('onSettingsCallCompleted', handleOnSettingsCallCompleted);
        attachedEventListiner = true;
    }
}
/**
 * Trigger the service cloud chat
 */
function startChatSession() {
    window.embedded_svc.liveAgentAPI.startChat({
        directToAgentRouting: {
            buttonId: window.embedded_svc.settings.buttonId,
            fallback: true
        },
        extraPrechatInfo: [null],
        extraPrechatFormDetails: []
    });
}

/**
  * Chat button click event handler for agent offline and online..
  */
function hideShowChatBtn() {
    const embeddedHelpButton = $('.embeddedServiceHelpButton');
    const offlineText = needHelpButton.data('offline-chat-text');
    const onlineText = needHelpButton.data('online-chat-text');

    if (!embeddedHelpButton.find(helpEnabledButton).length) {
        needHelpButton.attr('disabled', 'disabled');
        $('.js-chat-offline-message').removeClass('d-none');
        needHelpButton.find(chatText).text(offlineText);
        return;
    }

    $('.js-needhelp-emea-na').removeAttr('disabled');
    $('.js-chat-offline-message').addClass('d-none');
    needHelpButton.find(chatText).text(onlineText);
}

/**
 * On chat button click.
 */
function onChatButtonClick() {
    hideShowChatBtn();
    startChatSession();
    $chatModal.modal('toggle');
    $chatButton.addClass('d-none');
    $(document).trigger('chat:start_chat');
}

/**
  * Chat button click event handler from modal.
  */
function bindModalEvent() {
    needHelpButton.off('click', onChatButtonClick);
    window.embedded_svc.settings.displayHelpButton = false;
    const embeddedHelpButton = $('.embeddedServiceHelpButton');
    embeddedHelpButton.hide();
    needHelpButton.on('click', onChatButtonClick);
    hideShowChatBtn();
}
/**
 * Load chat script and  show/hide chat button inside help modal.
 */
function loadChatScriptsAndToggeleChatButton() {
    // Load the scripts needed.
    if (!window.eswLoaded) {
        window.loadESW();
    }
    needHelpButton.off('click', onChatButtonClick);
    // Poll for the presence of the result of a loaded script, the help button.
    const checkExist = setInterval(function () {
        const embeddedHelpButton = $('.embeddedServiceHelpButton');
        if (!embeddedHelpButton.length) {
            return;
        }
        clearInterval(checkExist);
        addEventHandlers();
        bindModalEvent();
    }, 300);
}
/**
 * This method will check the chat session is initiated or not
 * @return {boolean} checkts the preference values equal or not
 */
function isActiveChatSessions() {
    var liveAgentAPI = window.embedded_svc && window.embedded_svc.liveAgentAPI;
    var activeChatSessions = liveAgentAPI && liveAgentAPI.browserSessionInfo.activeChatSessions;
    return activeChatSessions && activeChatSessions > 0;
}
/**
 * Handles the click event on elements with class 'js-question-mark' and links pointing to '#jsLiveChat'.
 * Depending on the state of chat sessions, this function either starts a chat session or toggles the chat modal.
 */
function handelChatButtOnClick() {
    $(document).on('click', '.js-question-mark, a[href="#jsLiveChat"]', function () {
        if (isActiveChatSessions()) {
            startChatSession();
            $(document).trigger('chat:start_chat');
        } else {
            const onlineText = needHelpButton.data('online-chat-text');
            needHelpButton.find(chatText).text(onlineText);
            if (!window.eswLoaded) {
                loadChatScriptsAndToggeleChatButton();
            } else {
                bindModalEvent();
            }
            $chatModal.modal('toggle');
            $(document).trigger('chat:open_modal');
        }
    });
}
/**
 * Initialize.
 */
function init() {
    // window.loadESW is conditionally added by /int_servicecloud_sfra/cartridge/scripts/hooks/hookTemplate.js
    if (typeof window.loadESW !== 'function') {
        return;
    }

    // window.eswLoaded is conditionally added by /int_servicecloud_sfra/cartridge/scripts/hooks/hookTemplate.js
    if (typeof window.eswLoaded !== 'boolean') {
        return;
    }
    // Added a new "if" block to enable switching between the old and new chat feature flags.
    var $serviceHelp = $('.js-service-cloud-help');
    if ($serviceHelp.data('enable-service-cloud-help')) {
        $chatButton = $('.js-question-mark');
        needHelpButton = $('.js-needhelp-emea-na');
        $serviceHelp.removeClass('d-none');
        loadChatScriptsAndToggeleChatButton();
        handelChatButtOnClick();
    } else {
        needHelpButton = $('.js-needhelp');
        if (needHelpButton.length === 0) {
            return;
        }
        needHelpButton.addClass(needHelpButton.data('show-class'));
        if (!window.eswLoaded) {
            needHelpButton.on('click', loadChatScripts); // First load scripts, then show help button.
        } else {
            needHelpButton.on('click', showEmbeddedHelpButton); // Show help button.
        }
    }
}

module.exports = {
    init: init
};
