'use strict';

module.exports = {

    handleAttributeSelect() {
        // Update the PDP timer on variant selection.
        $('body').on('product:afterAttributeSelect', function (e, response) {
            var countdown = document.querySelector('#productDetails count-down');

            if (!countdown) {
                return;
            }

            if (response.data
                && response.data.hasCountdownTimer
                && response.data.launchDateTime
                && response.data.descriptionText
            ) {
                const launchDatedEnd = response.data.launchDateTime;
                const launchDateMessage = response.data.descriptionText;

                countdown.setAttribute('to', launchDatedEnd);
                countdown.setAttribute('description', launchDateMessage);
            } else {
                countdown.finish();
            }
        });
    },

    handleCountdownFinished() {
        // Update timer banner component when countdown finishes during page visit.
        $('body').on('countdown:finished', function (e) {
            const banner = e.target.closest('.countdown-timer-banner-wrapper');

            if (banner) {
                banner.classList.add('post-launch-banner');
            }
        });
    }

};
