/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */

/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var TRACKING_GLOBAL = 'tracking:global';

var pdpTealium = {
    init: function () {
        $(document).on(TRACKING_GLOBAL, '#recommender-tab', function () {
            if (window.utag_data) {
                var recommenderTab = {
                    event_type: 'in_store_pdp_tab',
                    page_name: window.utag_data.page_name,
                    ga_category: 'Store Experience',
                    ga_action: 'Tab Click',
                    ga_label: $(this).attr('data-attr')
                };
                tealium.triggerEvent(recommenderTab);
            }
        });

        $(document).on(TRACKING_GLOBAL, '#details-tab', function () {
            if (window.utag_data) {
                var detailsTab = {
                    event_type: 'in_store_pdp_tab',
                    page_name: window.utag_data.page_name,
                    ga_category: 'Store Experience',
                    ga_action: 'Tab Click',
                    ga_label: $(this).attr('data-attr')
                };
                tealium.triggerEvent(detailsTab);
            }
        });

        $(document).on(TRACKING_GLOBAL, '#reviews-tab', function () {
            if (window.utag_data) {
                var reviewsTab = {
                    event_type: 'in_store_pdp_tab',
                    page_name: window.utag_data.page_name,
                    ga_category: 'Store Experience',
                    ga_action: 'Tab Click',
                    ga_label: $(this).attr('data-attr')
                };
                tealium.triggerEvent(reviewsTab);
            }
        });

        $(document).on(TRACKING_GLOBAL, '#instore-tab', function () {
            var str = $(this).text();
            var trimmedStr = $.trim(str);
            if (window.utag_data) {
                var inStoreTab = {
                    event_type: 'in_store_pdp_tab',
                    page_name: window.utag_data.page_name,
                    ga_category: 'Store Experience',
                    ga_action: 'Tab Click',
                    ga_label: trimmedStr
                };
                tealium.triggerEvent(inStoreTab);
            }
        });

        $(document).on(TRACKING_GLOBAL, '#online-tab', function () {
            var str = $(this).text();
            var trimmedStr = $.trim(str);
            if (window.utag_data) {
                var onlineTab = {
                    event_type: 'in_store_pdp_tab',
                    page_name: window.utag_data.page_name,
                    ga_category: 'Store Experience',
                    ga_action: 'Tab Click',
                    ga_label: trimmedStr
                };
                tealium.triggerEvent(onlineTab);
            }
        });
    }
};

pdpTealium.init();
