/* global zE, $zopim */

'use strict';

var zendeskUtil = require('./zendeskUtil.js');

/**
 * Checks if Zendesk widget is loaded and a chat is in progress.
 * @returns {boolean} - Returns true if the widget is loaded and a chat is in progress, otherwise false.
 */
function isZendeskIntiated() {
    return $zopim && $zopim.livechat && $zopim.livechat.isChatting();
}

/**
 * Verifies whether the new chat feature is enabled and subsequently hides the default web widget.
 * @param {evet} event - hold the event data
 * @param {Object} data - hold the data object
 * @returns {void}
 */
var zeHideWidget = function (event, data) {
    if (data && data.zeHelp) {
        zE('webWidget', 'hide');
        $('.js-question-mark').show();
    }
};

/**
 * Shows and opens the Zendesk widget.
 * @returns {void}
 */
var zeShowWidget = function () {
    $('.js-question-mark').hide();
    zE('webWidget', 'show');
    zE('webWidget', 'open');
    $(document).trigger('zendesk:open');
    // ZENDESK ANALYTICS EVENT
    $(document).trigger('chat:start_chat');
};

/**
 * Handel custom chat button click event.
 * @returns {void}
 */
var handelCustomChatButtonClick = function () {
    if (isZendeskIntiated()) {
        $(document).trigger('zeWidget:show');
        // ZENDESK ANALYTICS EVENT
        $(document).trigger('chat:start_chat');
    } else {
        $('#emeaHelpModal').modal('toggle');
        $(document).trigger('chat:open_modal');
    }
};

/**
 * Set locale and department if available. Activate Zendesk widget
 *
 * @param {HTMLElement} zElement - Div holding Zendesk site preference data
 *
 */
function activateZendesk(zElement) {
    var zeVisitorDept = zElement.dataset.zeVisitorDept;
    var zeLocale = zElement.dataset.zeLocale;
    var zeHelp = zElement.dataset.zeHelp === 'true';
    zE('webWidget:on', 'open', function () {
        $(document).trigger('zendesk:open');
        zE('webWidget', 'updateSettings', {
            webWidget: {
                zIndex: 999999
            }
        });
    });

    zE('webWidget:on', 'close', function () {
        $(document).trigger('zeWidget:hide', { zeHelp });
        zE('webWidget', 'updateSettings', {
            webWidget: {
                zIndex: 3
            }
        });
    });
    // This event is added because when the chat is opened in a new window, it triggers the rendering of the default chat button.
    zE('webWidget:on', 'chat:popout', function () {
        $(document).trigger('zeWidget:hide', { zeHelp });
    });
    $('.js-needhelp-emea-na').on('click', function () {
        $('#emeaHelpModal').modal('toggle');
        $(document).trigger('zeWidget:show');
    });
    zE(function () {
        // set locale - reduce locale to language code except for some exceptions
        zeLocale = (zeLocale === 'en_GB' || zeLocale === 'fr_CA' || zeLocale === 'zh_HK' || zeLocale === 'zh_TW') ? zeLocale : zeLocale.substring(0, 2);
        if (['en', 'de', 'fr', 'es', 'it', 'nl', 'pt', 'en_GB', 'fr_CA', 'zh_HK', 'zh_TW'].indexOf(zeLocale) > -1) {
            // convert locale to zopim format
            var zopimChatLocale = zeLocale.toLowerCase().replace('_', '-');
            zE.setLocale(zopimChatLocale);
        }
        zE('webWidget', 'updateSettings', { // To make sure the zendesk icon doesn't overlap with filters or paypal checkout
            webWidget: {
                zIndex: 3 // translates to z-index : 2 in CSS for some reason
            }
        });
        // show the widget
        if (zeHelp) {
            zE.hide();
        } else {
            zE.show();
        }

        // $zopim function below never gets activated, this timeout removes loading icon from chat widgets clicked
        // TODO - zendesk code here is Legacy v1 API - update to command syntax for more flexibility
        // https://developer.zendesk.com/embeddables/docs/widget/api

        $zopim(function () {
            // set the department if site pref set
            if (zeVisitorDept !== 'null') {
                $zopim.livechat.departments.filter('');
                $zopim.livechat.departments.setVisitorDepartment(zeVisitorDept);
                $zopim.livechat.setOnConnected(function () {
                    var deptStatus = $zopim.livechat.departments.getDepartment(zeVisitorDept);
                    if (deptStatus.status === 'offline') {
                        // Set the account status to 'offline' and hide widget
                        $zopim.livechat.setStatus('offline');
                        $zopim.livechat.hideAll();
                    } else {
                        // The department is Online (At least one agent in that dept is Online)
                        // Automatically add a tag
                        // Adding a tag is completely optional. It can help with reporting in support
                        $zopim.livechat.addTags('new_balance_chat');
                        // set the Department
                        $zopim.livechat.departments.filter('');
                        $zopim.livechat.departments.setVisitorDepartment(zeVisitorDept);
                    }
                });
            }
        });
    });
}

/**
 * Initiate Zendesk widget if enabled in site preferences
 *
 * @param {HTMLElement} zElement - Div holding Zendesk site preference data
 *
 */
function initiateZendesk(zElement) {
    zendeskUtil.initiateZendeskScript(zElement);
    activateZendesk(zElement);
}

module.exports = {
    init: function () {
        if (document.getElementById('zendeskPrefs')) {
            // Pass in element containing data attributes needed to run Zendesk widget
            initiateZendesk(document.getElementById('zendeskPrefs'));
            $('.js-question-mark, a[href="#jsLiveChat"]').on('click', handelCustomChatButtonClick);
            $(document).on('zeWidget:show', zeShowWidget);
            $(document).on('zeWidget:hide', zeHideWidget);
        }
    }
};
