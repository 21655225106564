/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var utils = require('./utils');
var energyLaunchProduct = $('[is-energy-launch]');

var stagesEnum = Object.freeze({
    cart: 0,
    shipping: 1,
    payment: 2,
    order_review: 3,
    order_confirmation: 4
});

/**
 * Returns common data needed for checkout page view events
 * @returns {Object} - object with tealiumData
 */
function getCommonUtagData() {
    var keys = ['product_brand', 'product_category', 'product_color', 'product_gender', 'product_id', 'product_line', 'product_type', 'product_master', 'product_name',
        'product_quantity', 'product_size', 'product_sku', 'product_silhouette', 'product_style_number', 'product_subcategory', 'product_impression_id',
        'product_list', 'page_context_type', 'site_section', 'product_coupon_promo', 'item_id', 'item_category', 'item_category2', 'item_category3', 'item_category4', 'item_category5',
        'item_list_id', 'item_variant', 'site', 'login_status', 'user_id', 'element_type', 'element_text', 'checkout_type', 'original_product_unit_price', 'category_path', 'item_id_price'];

    return utils.populateFromKeys(keys, window.utag_data, {});
}

function populatePageData(utag_data, stage) {
    var baseData = utag_data;
    var keys = ['heirarchy', 'ip_address', 'page_type', 'page_section', 'site_sub_section', 'site_sub_section_level3', 'site_sub_section_level4',
        'site_sub_section_level5', 'error_text', 'country', 'language', 'locale', 'login_type', 'geoPostal', 'promo_id', 'promo_name',
        'promo_creative', 'promo_position', 'product_list', 'order_currency', 'user_flow', 'viewport', 'customer_email', 'hashed_email', 'user_anonymous',
        'user_authenticated', 'user_registered', 'customer_status', 'customer_id', 'customer_type', 'customer_city', 'customer_state', 'order_subtotal', 'product_name',
        'product_unit_price', 'product_variant_id', 'adobe_product_id', 'product_vat', 'product_coupon_discount', 'product_width', 'product_gbu', 'product_line', 'product_type', 'original_product_unit_price', 'category_path',
        'empty_cart', 'cart_order_subtotal', 'cart_product_name', 'cart_product_brand', 'cart_product_unit_price',
        'cart_product_sku', 'cart_product_style_number', 'adobe_cart_product_id', 'cart_product_id', 'cart_product_variant_id', 'cart_product_master', 'cart_product_category', 'cart_product_quantity',
        'cart_product_vat', 'cart_product_coupon_discount', 'cart_product_coupon_promo', 'item_list_name', 'item_category', 'item_category2', 'item_category3', 'item_category4', 'item_category5', 'item_list_id',
        'cart_product_gender', 'cart_product_color', 'cart_product_size', 'cart_product_width', 'cart_product_gbu', 'cart_product_line', 'cart_product_type', 'cart_product_silhouette',
        'item_variant', 'cart_original_product_unit_price', 'cart_product_category_path', 'item_id_price'];

    baseData.page_name = 'cart : checkout : ' + stage;
    baseData.page_group = stage;
    baseData.page_context_title = stage;
    baseData.item_variant = window.utag_data.cart_product_id;
    baseData = utils.populateFromKeys(keys, window.utag_data, baseData);
    baseData.page_type = stage;
    // energy launch specific code
    baseData.is_launch_event = window.sitePrefs.energyLaunchEnabled === true && energyLaunchProduct.length >= 1 ? 'true' : 'false';
    return baseData;
}

function createAddShippingInfoEvent(utagData, elementType, elementText) {
    var baseData = getCommonUtagData();
    var shipping_tier = $('.shipping-summary .shipping-method-title').text();
    var eventData = {
        event_type: 'add_shipping_info',
        page_name: utagData.page_name,
        page_group: window.utag_load_data && window.utag_load_data.page_group ? window.utag_load_data.page_group : '',
        page_type: window.utag_load_data && window.utag_load_data.page_type ? window.utag_load_data.page_type : '',
        page_context_title: utagData.page_context_title,
        site_type: utagData.site_type,
        item_list_name: utagData.category_path,
        item_name: utagData.cart_product_name,
        item_id: utagData.cart_product_master,
        price: utagData.cart_product_unit_price,
        item_brand: utagData.cart_product_brand,
        item_variant: utagData.cart_product_variant_id,
        coupon_item_level: utagData.coupon_item_level,
        coupon_order_level: utagData.coupon_order_level,
        quantity: utagData.cart_product_quantity,
        element_type: elementType,
        element_text: elementText,
        shipping_tier: shipping_tier,
        checkout_step: 'payment'
    };

    return Object.assign(baseData, eventData);
}

function createAddPaymentInfoEvent(utagData) {
    var baseData = getCommonUtagData();
    var eventData = {
        event_type: 'add_payment_info',
        site_type: utagData.site_type,
        item_name: utagData.product_name,
        price: utagData.product_unit_price,
        item_brand: utagData.product_brand,
        coupon_item_level: utagData.product_coupon_promo,
        quantity: utagData.product_quantity,
        coupon_order_level: utagData.basket_coupon_code,
        payment_type: utagData.selected_payment_method,
        checkout_step: 'payment'
    };

    return Object.assign(baseData, eventData);
}

function getViewData(checkout_step) {
    var eventData = {};
    var baseData = getCommonUtagData();

    switch (checkout_step) {
        case stagesEnum.cart:
            eventData = populatePageData(baseData, 'cart');
            break;
        case stagesEnum.shipping:
            eventData = populatePageData(baseData, 'shipping');
            break;
        case stagesEnum.payment:
            eventData = populatePageData(baseData, 'billing');
            var paymentInfo = createAddPaymentInfoEvent(window.utag_data);
            Object.assign(eventData, paymentInfo);
            break;
        case stagesEnum.order_review:
            eventData = populatePageData(baseData, 'review');
            break;
        case stagesEnum.order_confirmation:
            eventData = populatePageData(baseData, 'confirmation');
            break;
        default:
            eventData = populatePageData(baseData);
            break;
    }

    eventData.checkout_step = Object.keys(stagesEnum).find(
        function (stage) {
            return stagesEnum[stage] === checkout_step;
        }
    );
    if (!eventData.event_type) {
        eventData.event_type = 'checkout_progress';
    }

    return eventData;
}

var checkout = {
    init: function () {
        var CART_CHECKOUT_BILLING = 'cart : checkout : billing';
        var GIFT_CARD_APPLIED = 'Gift Card Applied';
        $(document).on('credit-card:error', function (e, data) {
            tealium.tealiumHelper('credit_card_error', CART_CHECKOUT_BILLING, 'Credit Card Error', data.gaAction, data.gaLabel);
        });

        $(document).on('selected-payment:method', function (e, data) {
            var eventType;
            var selected_payment_method;
            switch (data) {
                case 'credit-card-content':
                    eventType = 'credit card';
                    selected_payment_method = 'Credit Card';
                    break;
                case 'paypal-content':
                    eventType = 'pay_with_wallet';
                    selected_payment_method = 'Paypal';
                    break;
                case 'afterpay_pbi-content':
                    eventType = 'afterPay';
                    selected_payment_method = 'Afterpay';
                    break;
                case 'peach-payment-content':
                case 'peach-payment-cap-content':
                    eventType = 'peach_payments';
                    selected_payment_method = 'Peach Payments';
                    break;
                case 'adyen-component-content':
                    eventType = 'adyen';
                    selected_payment_method = 'Adyen';
                    break;
                case 'ecpay-content':
                    eventType = 'ec pay';
                    selected_payment_method = 'EC-Pay';
                    break;
                default:
                    eventType = data;
                    selected_payment_method = data;
                    break;
            }
            tealium.tealiumHelper(eventType, CART_CHECKOUT_BILLING, selected_payment_method, 'Payment Method', selected_payment_method, selected_payment_method);
        });

        $(document).on('update:shipping-info', function (e, data) {
            if (window.utag_data) {
                // New GA4 implementation
                var shippingData = createAddShippingInfoEvent(window.utag_data, data.elementType, data.elementText);
                tealium.triggerEvent(shippingData);

                if (document.getElementById('add-to-email-list').checked) {
                    var emailSubscribeObj = {
                        element_type: data.elementType,
                        element_text: data.elementText,
                        page_type: window.utag_load_data && window.utag_load_data.page_type ? window.utag_load_data.page_type : '',
                        event_type: 'email_signup',
                        event_detail: 'checkout signup checkbox',
                        page_location: window.location.pathname
                    };
                    tealium.triggerEvent(emailSubscribeObj);
                }
            }
        });

        $(document).on('update:checkout-step', function (e, data) {
            if (!window.utag_data) {
                return;
            }

            var ViewData = getViewData(data.checkout_step);
            tealium.triggerEvent(ViewData, 'view');
        });

        $(document).on('gift-card:applied', function (e) {
            tealium.tealiumHelper('gift_card', CART_CHECKOUT_BILLING, GIFT_CARD_APPLIED, GIFT_CARD_APPLIED, GIFT_CARD_APPLIED);
        });

        $(document).on('gift-card:error', function (e, data) {
            tealium.tealiumHelper('gift_card', CART_CHECKOUT_BILLING, 'Gift Card Error', data.gaAction, data.gaLabel);
        });

        $(document).on('afterpay:error-tealium', function () {
            tealium.tealiumHelper('afterPay', CART_CHECKOUT_BILLING, 'AfterPay Error', 'AfterPay Error', 'AfterPay');
        });
    }
};

exports.init = checkout.init;
