/* eslint-disable no-empty */
/* eslint-disable require-jsdoc */
/* eslint-disable no-undef */

'use strict';

var utils = require('./utils');
var TRACKING_GLOBAL = 'tracking:global';
var eventsQueue = [];
var birthdayEventString = 'birthday:event';
var editProfileEventStr = 'editprofile:event';

var eventObj = {
    emailEdit: 'email:edit',
    passwordEdit: 'password:edit',
    paymentOpen: 'paymentmethod:openPaymentForm',
    paymentAdd: 'paymentmethod:add',
    paymentRemove: 'paymentmethod:remove',
    formError: 'form:error'
};

function pushEventToQueue(attributeObj) {
    var newEvent = true;
    eventsQueue.forEach(function (eventItem) {
        if (attributeObj.eventMessage === eventItem.eventMessage) {
            newEvent = false;
        }
    });

    if (newEvent) {
        eventsQueue.push(attributeObj);
    }
}

function queueEvent(event, attribute) {
    var attributeObj = {};
    switch (event) {
        case editProfileEventStr:
            attributeObj.changeAction = 'account_edit_details';
            attributeObj.eventMessage = 'My Account | Edit details | ' + attribute;
            pushEventToQueue(attributeObj);
            break;
        case birthdayEventString:
            attributeObj.changeAction = 'account_birthday';
            attributeObj.eventMessage = 'My Account | Enter Birthday';
            pushEventToQueue(attributeObj);
            break;
        case 'shopFor:event':
            attributeObj.changeAction = 'who_shop_for';
            attributeObj.eventMessage = 'My Account | Shopping For | ' + attribute;
            pushEventToQueue(attributeObj);
            break;
        case 'myFit:event':
            attributeObj.changeAction = 'my_fit';
            attributeObj.eventMessage = 'My Account | My Fit | ' + attribute;
            pushEventToQueue(attributeObj);
            break;
        default:
            break;
    }
}

module.exports = {
    init: function () {
        $(document).on(eventObj.emailEdit, function () {
            var triggerEventObj = {
                event_type: 'my_account_change',
                change_action: 'edit_email',
                event_message: 'My Account | Edit Email'
            };
            utils.fireTealium(triggerEventObj);
        });

        $(document).on(eventObj.passwordEdit, function () {
            var triggerEventObj = {
                event_type: 'my_account_change',
                change_action: 'edit_password',
                event_message: 'My Account | Edit Password'
            };
            utils.fireTealium(triggerEventObj);
        });

        $(document).on(eventObj.paymentOpen, function () {
            var triggerEventObj = {
                event_type: 'my_account_change',
                change_action: 'start_payment_method',
                event_message: 'My Account | Start Payment Method'
            };
            utils.fireTealium(triggerEventObj);
        });

        $(document).on(eventObj.paymentAdd, function () {
            var triggerEventObj = {
                event_type: 'my_account_change',
                change_action: 'add_payment_method',
                event_message: 'My Account | Add Payment Method'
            };
            utils.fireTealium(triggerEventObj);
        });

        $(document).on(eventObj.paymentRemove, function () {
            var triggerEventObj = {
                event_type: 'my_account_change',
                change_action: 'remove_payment_method',
                event_message: 'My Account | Remove Payment Method'
            };
            utils.fireTealium(triggerEventObj);
        });

        $(document).on(eventObj.formError, function (e, json) {
            var objectErrorKey = Object.keys(json)[0];
            var objectErrorVal = Object.values(json)[0];
            var triggerEventObj = {
                event_type: 'error_tracking',
                change_action: objectErrorKey,
                event_message: 'My Account | ' + objectErrorVal
            };
            utils.fireTealium(triggerEventObj);
        });

        // Edit Personal & Account Details
        $(document).on(birthdayEventString, function () {
            queueEvent(birthdayEventString);
        });

        $(document).on('change', '.edit-profile-form .edit-profile-form select', function (e) {
            var description = e.target.id + ' - ' + e.target.value;
            queueEvent('myFit:event', description);
        });

        $(document).on('change', '#Men, #Women, #Girls, #Boys', function (e) {
            var target = e.target;
            var selected = target.checked ? 'Selected' : 'Deselected';
            var description = e.target.id + ' - ' + selected;
            queueEvent('shopFor:event', description);
        });

        $(document).on('change', '#firstName, #lastName, #email, #phone, #birthday', function (e) {
            var description = e.target.id;
            queueEvent(editProfileEventStr, description);
        });

        $(document).on('profile:edit', function () {
            eventsQueue.forEach(function (eventItem) {
                var triggerEventObj = {
                    event_type: 'my_account_change',
                    change_action: eventItem.changeAction,
                    event_message: eventItem.eventMessage
                };
                utils.fireTealium(triggerEventObj);
            });
        });

        $(document).on(TRACKING_GLOBAL, '.myfit .it-body-not-set .fit-links', function () {
            var triggerEventObj = {
                event_type: 'wishlist_my_fit',
                filter: 'My Fit',
                ga_action: 'My Fit',
                ga_label: ''
            };
            utils.fireTealium(triggerEventObj);
        });

        $(document).on(TRACKING_GLOBAL, '.myfit .fit-body-set .select-fit', function () {
            var type = $(this).attr('data-type').split(':')[0];
            var triggerEventObj = {
                event_type: 'wishlist_my_fit',
                my_fit_filter: type,
                ga_action: 'My Fit | Size Selection',
                ga_label: ''
            };
            utils.fireTealium(triggerEventObj);
        });
    }
};
