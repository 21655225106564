'use strict';

var debounce = require('../util/debounce');

/**
 * This function is used for sticky Header on scroll
 */
function stickyHeader() {
    $(window).on('scroll', function () {
        var $this = $(this);
        debounce(function () {
            var windowScroll = $this.scrollTop();
            var bannerHeight = $('.header-banner').innerHeight();
            var shoeFinderProgressBar = $('.pt_shoefinder .progress-bar');
            if (windowScroll > bannerHeight) {
                $('.js-header-fixed').attr('style', 'top:-' + bannerHeight + 'px;');
                if (shoeFinderProgressBar.length && shoeFinderProgressBar.is(':visible')) {
                    shoeFinderProgressBar.attr('style', 'top:' + $('.header-navigation').innerHeight() + 'px;');
                    shoeFinderProgressBar.css('display', 'block');
                }
            } else {
                $('.js-header-fixed').attr('style', 'top:-' + windowScroll + 'px; transition: 0.3s;');
                if (shoeFinderProgressBar.length && shoeFinderProgressBar.is(':visible')) {
                    shoeFinderProgressBar.attr('style', 'top:' + ($('.header-navigation').innerHeight() + $('.header-banner').innerHeight()) + 'px;');
                    shoeFinderProgressBar.css('display', 'block');
                }
            }
        }, 100)();
    });
}

/**
 * This function is used for headerpromo bar on click overlay effects
 */
function headerPromobar() {
    $('body').on('show.bs.collapse', '#Promo_bar_details_Container', function () {
        $('.js-header-fixed .js-overlay').removeClass('d-none');
        $('body').addClass('overflow-hidden');
    });

    $('body').on('hidden.bs.collapse', '#Promo_bar_details_Container', function () {
        $('.js-header-fixed .js-overlay').addClass('d-none');
        $('body').removeClass('overflow-hidden');
    });
}

/**
 * @function headerBannerCursor
 * @description This function is used to add pointer cursor on global banner above navigation
 * if there is data in collapsable container.
 */
function headerBannerCursor() {
    var $closeIcon = $('#Promo_bar_details_Container .banner-close'); // Check if close Icon exists or not
    if ($closeIcon.length > 0 && $closeIcon.attr('href') === '#Promo_bar_details_Container') {
        // If close icon contains collapsable container ID in href then add cursor pointer on div conataining link.
        $('.promobarOpen').attr('role', 'button');
    } else {
        $('.promobarOpen').removeAttr('role');
    }
}

module.exports = {
    headerBannerCursor: headerBannerCursor,
    stickyHeader: stickyHeader,
    headerPromobar: headerPromobar
};
