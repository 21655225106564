'use strict';

// NCSS accessibility mouse/keyboard focus styles
(function () {
    var onMouseFocus = function () {
        window.requestAnimationFrame(function () {
            var body = document.querySelector('body');
            body.classList.add('mouse-focus');
            body.classList.remove('keyboard-focus');
        });
    };
    var onKeyboardFocus = function (e) {
        window.requestAnimationFrame(function () {
            var body = document.querySelector('body');
            body.classList.remove('mouse-focus');
            if (e.keyCode === 9) {
                body.classList.add('keyboard-focus');
            }
        });
    };
    window.addEventListener('mousedown', onMouseFocus);
    window.addEventListener('keydown', onKeyboardFocus);
}());
