/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable require-jsdoc */
/* eslint-disable eqeqeq */
/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');

/**
 * Common analytics data used in chat events
 * @returns {Object} having common properties used across chat modal events
 */
function commonAnalyticsData() {
    var commonProps = {};
    if (window.utag_data) {
        commonProps = {
            site: window.utag_data.site,
            site_section: window.utag_data.site_section,
            page_name: window.utag_data.page_name,
            page_group: window.utag_data.page_type,
            page_context_type: window.utag_data.page_context_type,
            page_context_title: window.utag_data.page_context_title,
            login_status: window.utag_data.login_status,
            site_type: window.utag_data.site_type,
            user_id: window.utag_data.user_id
        };
    }
    return commonProps;
}

/**
 * Decode the URL and get the value
 * @param {string} url - href
 * @param {string} searchDisplayName - serach for the category
 */

function getSelectedFiltersFromURL(url) {
    var queryString = url;
    var urlParams = {};
    var pairs;
    if (queryString.indexOf('?') > -1) {
        pairs = queryString.split('?')[1].split('&');
    } else {
        return '';
    }
    for (var pair = 0; pair < pairs.length; pair += 1) {
        var keyVal = pairs[pair].split('=');
        urlParams[keyVal[0]] = decodeURIComponent(keyVal[1]);
    }
    var listOfSelectedFilters = [];
    var totalParams = Object.keys(urlParams).filter(function (key) {
        return key.includes('prefn');
    });
    for (var c = 1; c < totalParams.length + 1; c += 1) {
        if (urlParams['prefn' + c]) {
            var prefValue = urlParams['prefv' + c] && urlParams['prefv' + c].indexOf('|') > -1 ? JSON.stringify(urlParams['prefv' + c].split('|')) : urlParams['prefv' + c] || null;
            listOfSelectedFilters.push(urlParams['prefn' + c] + '=' + prefValue);
        }
    }
    if (urlParams.pmin || urlParams.pmax) {
        var min = urlParams.pmin || null;
        var max = urlParams.pmax || null;
        listOfSelectedFilters.push('price=' + min + '-' + max);
    }
    return listOfSelectedFilters.join(' | ');
}

var filter = {
    init: function () {
        $('#tl_filter_category').click(function (e) {
            if (window.utag_data) {
                var $currentElement = $(e.target);
                var filterEventData = {};
                var filterLabelClass = '.js-filter-label';
                var refinementCategory = $currentElement.closest('div.refinement').data('refinement');
                var tlObject = {
                    event_type: 'Filter Clicked',
                    element_type: $currentElement[0].nodeName,
                    element_text: $currentElement.text().trim(),
                    page_location: window.location.pathname,
                    filter_type: refinementCategory
                };
                // Filter color link click events
                var refinementFitElem = $currentElement.closest('.card-body');
                var mainCategory = window.utag_data.main_nav_category;
                var commonProps = commonAnalyticsData();
                if (refinementFitElem.attr('id') == 'refinement-categories' || refinementFitElem.attr('id') == 'guestrefinement-categories' || refinementFitElem.attr('id') == 'pre-refinement-categories') {
                    if ($currentElement.closest('.refinment-myfit').length > 0 && $currentElement.closest('.myfit-unset').length <= 0) {
                        // Filter myfit  link click events
                        var myfitContainer = $('.myfit-container').children();
                        var labelMyfit = myfitContainer[0].children[0].innerText.split(':')[0] + '- ' + myfitContainer[0].children[1].innerText.split(':')[0] + '|'
                            + myfitContainer[1].children[0].innerText.split(':')[0] + '- ' + myfitContainer[1].children[1].innerText.split(':')[0];
                        tlObject.ga_action = 'My Fit';
                        tlObject.filter_selected = labelMyfit;
                        tlObject.all_filters_selected = 'mainCategory=' + mainCategory + ' |  subCategory=' + labelMyfit;
                        tlObject.ga_label = labelMyfit;
                        tlObject.ga4_event = 'filter_applied';
                        filterEventData = Object.assign(commonProps, tlObject);
                        tealium.triggerEvent(filterEventData);
                    } else if ($currentElement.closest('.refinment-myfit').length <= 0) {
                        // Filter category  link click events
                        var labelCat = $currentElement.hasClass(filterLabelClass) ? $currentElement.text().trim() : $currentElement.closest('button.tl_filter_categories').find(filterLabelClass).text().trim();
                        var filterLink = $currentElement.closest('button.tl_filter_categories').data('href');
                        var filtersData = getSelectedFiltersFromURL(filterLink);
                        filtersData = filtersData && filtersData.length > 0 ? ' | ' + filtersData : '';
                        tlObject.filter_selected = labelCat;
                        tlObject.all_filters_selected = 'mainCategory=' + mainCategory + ' | subCategory=' + labelCat + filtersData;
                        tlObject.ga_action = refinementCategory;
                        tlObject.ga_label = labelCat;
                        tlObject.ga4_event = 'filter_applied';
                        filterEventData = Object.assign(commonProps, tlObject);
                        tealium.triggerEvent(filterEventData);
                    }
                }
            }
        });

        // Filter sort by  link click events
        $('.container').on('change', '[name=sort-order]', function () {
            if (window.utag_data) {
                var value = ($(this).val() || '').split('?');
                var querystring = value[1] || '';
                var sruleMatch = querystring.match(/srule=([^&]+)/);
                var label = sruleMatch ? sruleMatch[1].split('-').join(' ') : '';

                if (!label) {
                    return;
                }

                var sortbyObject = {
                    event_type: 'Sort',
                    page_name: window.utag_data.page_name,
                    event_message: 'sort by | ' + window.utag_data.main_nav_category + ' | ' + label,
                    ga_action: 'Sort',
                    ga_label: label
                };
                tealium.triggerEvent(sortbyObject);
            }
        });

        // Filter hide and show link click events
        $('.hide-filters').click(function () {
            if (window.utag_data) {
                var hideObject = {
                    event_type: 'hide_filter',
                    page_name: window.utag_data.page_name,
                    filter: 'Hide Filter',
                    ga_action: 'Hide Filter',
                    ga_label: ''

                };
                tealium.triggerEvent(hideObject);
            }
        });
    }

};

filter.init();

/**
 * Get url and element when user selects any category
 * @param {string} href - href value
 * @param {string} e - element
 */
window.filterTriggerEvent = function (href, e) {
    if (window.utag_data) {
        var $currentElement = $(e.currentTarget);
        var refinementCategory = $currentElement.closest('div.refinement').data('refinement');
        var selectedFilter = $currentElement.find('.js-filter-label').text().trim();
        var tlObject = {
            event_type: 'Filter Clicked',
            ga4_event: 'filter_applied',
            element_type: $currentElement[0].nodeName,
            element_text: selectedFilter,
            filter_type: refinementCategory,
            filter_selected: selectedFilter,
            page_location: window.location.pathname,
            ga_label: selectedFilter,
            ga_action: refinementCategory
        };
        var commonProps = commonAnalyticsData();
        var filtersData = getSelectedFiltersFromURL(href);
        filtersData = filtersData && filtersData.length > 0 ? filtersData : '';
        tlObject.all_filters_selected = filtersData;
        var filterEventData = Object.assign(commonProps, tlObject);
        tealium.triggerEvent(filterEventData);
    }
};
