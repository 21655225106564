/* global Promise */

'use strict';

var scriptCache = {};

/**
 * Load an external script.
 * @param {string} url Absolute URL of script to load
 * @param {string=} name Name of global variable that the script is expected to define
 * @return {Promise} A returned promise for the loading of the script
 */
module.exports = function loadScript(url, name) {
    var promise;

    if (scriptCache[url]) {
        // TODO: normalize URL
        promise = scriptCache[url];
    } else {
        promise = new Promise(function (resolve, reject) {
            var script = document.createElement('script');
            script.onerror = function () {
                return reject(new Error('Failed to load ' + url));
            };
            script.onload = resolve;
            script.async = true;
            script.src = url;

            if (document.currentScript) {
                document.currentScript.parentNode.insertBefore(script, document.currentScript);
            } else {
                (document.head || document.getElementsByTagName('head')[0]).appendChild(script);
            }
        });

        scriptCache[url] = promise;
    }

    return promise.then(function () {
        if (global[name]) {
            return global[name];
        }
        throw new Error(name + ' was not created by ' + url);
    });
};
