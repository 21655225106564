/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');

var orderStatus = {
    init: function () {
        $('.order-status form[name=dwfrm_orderstatus] .submit-button').click(function () {
            if (window.utag_data) {
                var trackOrder = {
                    event_type: 'track_order',
                    page_name: window.utag_data.page_name,
                    event_message: 'Track Order',
                    ga_action: 'Track Order',
                    ga_label: window.utag_data.user_authenticated === 'true' ? 'Traditional' : ''

                };
                tealium.triggerEvent(trackOrder);
            }
        });

        $('.order-status .button-secondary').click(function () {
            if (window.utag_data) {
                var trackOrder = {
                    event_type: 'login_order_status',
                    page_name: window.utag_data.page_name,
                    event_message: 'Track Order',
                    ga_action: 'Login | ' + window.utag_data.page_name,
                    ga_label: 'Traditional'

                };
                tealium.triggerEvent(trackOrder);
            }
        });
    }
};
orderStatus.init();
