/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */

/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var TRACKING_GLOBAL = 'tracking:global';
var styliticsProductID;

/**
 * Returns common data needed for the product comparison component
 * @param {Object} windowUtagData - window.utag_data client side global object
 * @returns {Object} - object with tealiumData
 */
function getCommonUtagData(windowUtagData) {
    var utagData = windowUtagData;
    return {
        ip_address: utagData.ip_address,
        page_name: utagData.page_name,
        pdp_state: utagData.pdp_state,
        page_type: 'product',
        page_context_type: 'product',
        site_section: utagData.site_section,
        page_section: utagData.page_section,
        site_sub_section: utagData.site_sub_section,
        site_sub_section_level3: utagData.site_sub_section_level3,
        site_sub_section_level4: utagData.site_sub_section_level4,
        site_sub_section_level5: utagData.site_sub_section_level5,
        error_text: utagData.error_text,
        customer_id: utagData.customer_id,
        customer_status: utagData.customer_status,
        customer_type: utagData.customer_type,
        site_type: utagData.site_type,
        country: utagData.country,
        language: utagData.language,
        locale: utagData.locale,
        login_type: utagData.login_type,
        geoPostal: utagData.geoPostal,
        promo_id: utagData.promo_id,
        promo_name: utagData.promo_name,
        promo_creative: utagData.promo_creative,
        promo_position: utagData.promo_position,
        order_currency: utagData.order_currency
    };
}

/**
 * Returns common data needed for the promotion component
 * @param {string} elem - dom element
 * @param {Object} windowUtagData - window.utag_data client side global object
 * @returns {Object} - object with tealiumData
 */
function getCommonPromotionData(elem, windowUtagData) {
    var utagData = windowUtagData;

    var elemType = elem[0].nodeName;
    var elemText = elem[0].text;
    return {
        site: utagData.site,
        site_section: utagData.site_section,
        page_name: utagData.page_name,
        page_group: utagData.page_type,
        page_context_title: utagData.page_context_title,
        login_status: utagData.customer_status,
        site_type: utagData.site_type,
        user_id: window.utag_data.user_id,
        element_type: elemType,
        element_text: elemText,
        promotion_id: elem.data('promoid'),
        promotion_name: elem.data('promoname'),
        creative_name: elem.data('promocreative'),
        creative_slot: elem.data('promoposition'),
        ga_label: elem.data('bannerid')
    };
}

/**
 * Triggers the specified tealium event for the product comparison component
 * @param {string} masterID - master product id
 * @param {string} eventType - custom event type
 * @param {string} eventAction - custom event name
 */
function fireEvent(masterID, eventType, eventAction) {
    var productInfo = tealium.getProductDetails(masterID);
    productInfo.then(function (data) {
        var baseData = getCommonUtagData(window.utag_data);
        var productData = {
            event_type: eventType,
            ga_action: eventAction,
            ga_label: data.product_name,
            product_id: data.product_id,
            product_sku: data.product_sku,
            product_name: data.product_name,
            product_style_number: data.product_style_number
        };
        var notifyObj = Object.assign(baseData, productData);
        tealium.triggerEvent(notifyObj);
    });
}

/**
 * Page Designer component fire view events on component clicks
 */

var experienceTelium = {
    init: function () {
        $(document).on(TRACKING_GLOBAL, '.experience-component a', function () {
            if (window.utag_data) {
                var $bannerMeta = $(this).closest('.experience-component').find('.analytics-meta');
                if ($bannerMeta.length) {
                    var basePromoData = getCommonPromotionData($bannerMeta, window.utag_data);
                    var experienceClick = {
                        page_context_type: window.utag_data.page_context_type,
                        event_type: 'select_promotion',
                        event_message: 'Banner Click | ' + $bannerMeta.data('bannerid'),
                        ga_action: 'Banner Click'
                    };

                    var experienceClickOld = {
                        event_type: 'promo_click',
                        page_name: window.utag_data.page_name,
                        event_message: 'Banner Click | ' + $bannerMeta.data('bannerid'),
                        promo_id: $bannerMeta.data('promoid'),
                        promo_name: $bannerMeta.data('promoname'),
                        promo_creative: $bannerMeta.data('promocreative'),
                        promo_position: $bannerMeta.data('promoposition'),
                        ga_action: 'Banner Click',
                        ga_label: $bannerMeta.data('bannerid')
                    };

                    var notifyObj = Object.assign(basePromoData, experienceClick);

                    tealium.triggerEvent(experienceClickOld);
                    tealium.triggerEvent(notifyObj);
                }
            }
        });

        $(document).on('emailsignupcomponent', function (event, masterID) {
            if (window.utag_data && masterID !== '') {
                var productInfo = tealium.getProductDetails(masterID);
                productInfo.then(function (data) {
                    var notifyObj = {
                        event_type: 'product_available_notice',
                        page_name: window.utag_data.page_name,
                        adobe_user_flow: 'product detail',
                        product_name: data.product_name,
                        product_brand: data.product_brand,
                        product_unit_price: data.product_unit_price,
                        product_master: data.product_master,
                        product_category: data.product_category,
                        product_subcategory: data.product_subcategory,
                        product_quantity: data.product_quantity,
                        product_vat: data.product_vat,
                        product_gender: data.product_gender,
                        ga_action: 'Notify-Subscribe',
                        ga_label: masterID,
                        category_path: data.category_path,
                        original_product_unit_price: data.original_product_unit_price
                    };
                    tealium.triggerEvent(notifyObj);
                });
            }
        });

        $(document).on('productcompare:impression', function (event, masterProducts) {
            if (window.utag_data && masterProducts !== '') {
                var selectedProducts = JSON.parse(masterProducts);
                var productIds = selectedProducts.map(function (product) { return product.pid; });
                var productNames = selectedProducts.map(function (product) { return product.name; });
                var baseData = getCommonUtagData(window.utag_data);
                var productData = {
                    event_type: 'shoe_comparison_tool_load',
                    ga_action: 'impression',
                    ga_label: productNames,
                    product_id: productIds,
                    product_sku: '',
                    product_name: productNames,
                    product_style_number: ''
                };
                var notifyObj = Object.assign(baseData, productData);
                tealium.triggerEvent(notifyObj);
            }
        });

        $(document).on('productcompare:dropdown', function (event, masterID) {
            if (window.utag_data && masterID !== '') {
                fireEvent(masterID, 'shoe_comparison_tool_select', 'select product');
            }
        });

        $(document).on('productcompare:image', function (event, masterID) {
            if (window.utag_data && masterID !== '') {
                fireEvent(masterID, 'shoe_comparison_tool_image', 'select product image');
            }
        });

        $(document).on('productcompare:shopnow', function (event, masterID) {
            if (window.utag_data && masterID !== '') {
                fireEvent(masterID, 'shoe_comparison_tool_shop_now', 'shop now');
            }
        });

        /**
         * Fires the stylitics event to get the data for the object
         * @param {string} event_detail2 - custom event type
         * @param {string} event_detail3 - custom event type
         * @param {string} event_type - custom event type
         * @param {string} ga_action - custom event type
         */
        function fireStyliticsEvent(event_detail2, event_detail3, event_type, ga_action) {
            var productId = $('.container.product-detail.product-wrapper').data('pid');
            var productInfo = tealium.getProductDetails(productId);
            productInfo.then(function (data) {
                var baseData = getCommonUtagData(window.utag_data);
                var productData = {
                    event_detail: 'stylitics_shop_the_look',
                    event_detail2: event_detail2,
                    event_type: event_type,
                    ga_action: ga_action,
                    ga_label: data.product_name,
                    ga4_event: 'user_tool',
                    in_stock_status: data.in_stock_status,
                    item_brand: data.product_brand,
                    item_category: data.product_category,
                    item_id: data.product_id,
                    item_name: data.product_name,
                    item_variant: data.product_variant_id,
                    pdp_availability: data.product_availability,
                    product_id: data.product_id,
                    product_name: data.product_name,
                    product_sku: data.product_sku,
                    product_style_number: data.product_style_number
                };

                if (event_detail3) {
                    productData.event_detail3 = event_detail3;
                }

                var notifyObj = Object.assign(baseData, productData);
                tealium.triggerEvent(notifyObj);
            });
        }

        /**
         * Get the product ID from the stylitics card
         * @param {string} eventItem - custom event type
         */
        function setStyliticsProductID(eventItem) {
            styliticsProductID = eventItem.split('/').pop().match(/^[^.]+/);
        }

        $(document).on('stylitics:jumplink', function () {
            if (window.utag_data) {
                fireStyliticsEvent('anchor', null, 'shop_the_look_anchor', 'click');
            }
        });

        $(document).on('stylitics:shopdetail', function (event) {
            if (window.utag_data) {
                setStyliticsProductID(event.delegateTarget.activeElement.pathname);
                fireStyliticsEvent('shop_detail', styliticsProductID, 'shop_the_look_shop_detail', 'click');
            }
        });

        $(document).on('stylitics:viewdetails', function () {
            if (window.utag_data) {
                var elementsArray = [];
                // This setTimeout is required. Since the data we need is not immediately available due to the items living in a
                // slideout that load in only after the click event, we must give it a few seconds to load in so we can grab each
                // of the items before pushing it into an array.
                setTimeout(function () {
                    $('.stylitics-dimension-collage-product-details').find('li .stylitics-ui-item-info').each(function () {
                        setStyliticsProductID($(this).attr('href'));
                        elementsArray.push(styliticsProductID[0]);
                    });
                    fireStyliticsEvent('view_details', elementsArray, 'shop_the_look_view_details', 'click');
                }, 3500);
            }
        });

        $(document).on('stylitics:seemoreoptionsproduct', function (event, masterID) {
            if (window.utag_data && masterID !== '') {
                fireEvent(masterID, 'shop_the_look_see_more_options_product', 'click');
            }
        });
    }
};

experienceTelium.init();
